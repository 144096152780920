import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import Toggle from '../../Components/Toggle';
import { ToggleControlValue } from './models/Controls';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import petImages from './utils/pet-images';
import { WaffleLightGrey } from '../../Constants/Style';
import LabeledIcon from './LabeldIcon';
import { Spacer } from '../../Components/SimpleComponents';
import CalloutText from './CalloutText';

type Props = {
  // This is solely used to prevent toggling. The core reason
  // is that wellness prime should be disabled if wellness basic is enabled
  isDisabled: boolean;
  hasWellnessPrime: boolean;
  hasWellnessPrimeControl: ToggleControlValue;
};

const Row = styled.View`
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
`;

const HeaderRow = styled.View`
  flex-direction: row;
  width: 100%;
  max-height: 65px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-width: 3px;
  border-bottom-color: ${WaffleLightGrey};
  flex: 1;
`;

const TitleSection = styled.View`
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(WaffleText)`
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.5px;
  font-weight: 500;
`;

const wellnessPreventions = [
  {
    label: 'Annual Exam',
    source: petImages.accidents,
  },
  {
    label: 'Deworming',
    source: petImages.deWorming,
  },
  {
    label: 'Health Certificate',
    source: petImages.healthCertificate,
  },
  {
    label: 'Blood Tests',
    source: petImages.bloodTests,
  },
  {
    label: 'Fecal Tests',
    source: petImages.poopTest,
  },
  {
    label: 'Vaccines',
    source: petImages.vaccines,
  },
  {
    label: 'Dental Cleaning',
    source: petImages.dental,
  },
  {
    label: 'Urine Test',
    source: petImages.urine,
  },
];

export default function WellnessPrime({
  isDisabled,
  hasWellnessPrime,
  hasWellnessPrimeControl,
}: Props): React.ReactElement {
  return (
    <CoverageCard bgColor="white">
      <HeaderRow>
        <TitleSection>
          <TitleText>Wellness Prime</TitleText>
          <CalloutText>(+$24.95/Mo)</CalloutText>
        </TitleSection>
        <Toggle
          isDisabled={isDisabled}
          isEnabled={hasWellnessPrime}
          onPress={hasWellnessPrimeControl.onToggleAsync}
        />
      </HeaderRow>
      <Spacer x={2} y={2} />
      <Row>
        {wellnessPreventions.map((prevention) => (
          <LabeledIcon
            isActive={hasWellnessPrime}
            source={prevention.source}
            label={prevention.label}
            key={prevention.label}
          />
        ))}
      </Row>
    </CoverageCard>
  );
}
