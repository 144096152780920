import React from 'react';
import styled from 'styled-components/native';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lockIconSrc = require('./assets/lock-icon.png');

const LockIcon = styled.Image`
  width: 23px;
  height: 23px;
`;

export default () => <LockIcon source={lockIconSrc} />;
