import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';

const CalloutText = styled(WaffleText)`
  color: #dd6f26;
  letter-spacing: -0.28px;
  text-align: center;
  line-height: 29px;
  font-style: normal;
  font-size: 14px;
`;

export default CalloutText;
