import { VerticalCenter } from '../../Components/SimpleLayouts';
import { BigBoldText } from '../../Components/WaffleText';
import React from 'react';

export default function InsuranceTitle({ children }: { children: string }) {
  return (
    <VerticalCenter style={{ width: '100%' }}>
      <BigBoldText
        testID="InsuranceTitle"
        style={{
          color: 'rgba(64, 64, 64, 0.78)',
          fontSize: 36,
          fontWeight: '400',
          lineHeight: 43,
          maxWidth: 334,
          textAlign: 'center',
        }}>
        {children}
      </BigBoldText>
    </VerticalCenter>
  );
}
