import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import React, { useState } from 'react';
import Popup from '../../Components/Popup';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../../screen-config';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import CustomLinking from '../../Helper/CustomLinking';
import { Spacer } from '../../Components/SimpleComponents';
import { getLifeRedirectLink } from '../LifeQuoteScreen/models/application';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Header = styled(WaffleText)`
  font-size: 23px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
`;

type Props = {
  userId: string;
};

export const LinkToLife = ({ userId }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const userTracking = useUserTracking();

  async function onContinueAsync() {
    setModalIsOpen(false);
    await userTracking.publishExternalPolicyClicked('Life', 'Ladder');
    const response = await getLifeRedirectLink(userId);

    navigation.navigate(HomeScreenName);
    await CustomLinking.openURL(response.link, '_blank');
  }

  return (
    <>
      <Icon
        name={'file-document-outline'}
        size={25}
        onPress={() => setModalIsOpen(true)}
      />
      <Spacer y={0.5} />
      <WaffleText style={{ fontSize: 8 }}>View Policy</WaffleText>
      <Popup visible={modalIsOpen} onDismiss={() => setModalIsOpen(false)}>
        <Container>
          <Header>Ladder Life Insurance</Header>
          <Spacer y={2} />
          <WaffleText>
            You will now be taken to our partner, Ladder Life Insurance, to view
            your policy.
          </WaffleText>
          <Spacer y={2} />
          <WaffleText>
            If there are any issues, please email us at contact@waffle-labs.com.
          </WaffleText>
          <Spacer y={2} />
          <WaffleButtonAsync name="Continue" onPress={onContinueAsync} />
        </Container>
      </Popup>
    </>
  );
};
