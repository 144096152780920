import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spacer } from '../../Components/SimpleComponents';
import cardElementStyle from './card-element-styling';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExpElementContainer = styled.div<{ isFocused: boolean }>`
  flex-grow: 3;
  border-radius: 8px;
  border: 1px solid #f2f4f5;
  padding: 0;
  padding-top: 12px;
  padding-left: 8px;
  background-color: ${(p) => (!p.isFocused ? '#f2f4f5' : 'white')};
`;

const CVCElementContainer = styled.div<{ isFocused: boolean }>`
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid #f2f4f5;
  background-color: ${(p) => (!p.isFocused ? '#f2f4f5' : 'white')};
  padding: 0;
  padding-top: 12px;
  padding-left: 8px;
`;

const MonthCVCContainer = styled.div`
  display: flex;
  width: 326px;
  height: 50.21px;
  flex-direction: row;
  padding: 0;
`;

const CardNumberElementContainer = styled.div<{ isFocused: boolean }>`
  width: 316px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #f2f4f5;
  background-color: ${(p) => (!p.isFocused ? '#f2f4f5' : 'white')};
  padding: 0px;
  padding-left: 8px;
  padding-top: 12px;
`;

type PaymentFormProps = {
  onEditing: () => void;
  onFormIsValidCheck: (boolean) => void;
  onZipCodeChange: (string) => void;
};

const PaymentForm = ({ onEditing, onFormIsValidCheck }: PaymentFormProps) => {
  const [expIsValid, setIsExpValid] = useState(false);
  const [cardIsValid, setCardIsValid] = useState(false);
  const [cvcIsValid, setCvcIsValid] = useState(false);
  const [expIsFocused, setIsExpFocused] = useState(false);
  const [cardIsFocused, setCardIsFocused] = useState(false);
  const [cvcIsFocused, setCvcIsFocused] = useState(false);

  useEffect(() => {
    onFormIsValidCheck(expIsValid && cardIsValid && cvcIsValid);
  });

  function onCardNumberChange(event: StripeCardNumberElementChangeEvent) {
    if (event.complete) {
      if (!cardIsValid) {
        setCardIsValid(true);
      }
    }

    onEditing();
  }

  function onExpChange(event: StripeCardExpiryElementChangeEvent) {
    if (event.complete) {
      if (!expIsValid) {
        setIsExpValid(true);
      }
    }

    onEditing();
  }

  function onCvcChange(event: StripeCardCvcElementChangeEvent) {
    if (event.complete) {
      if (!cvcIsValid) {
        setCvcIsValid(true);
      }
    } else {
      if (cvcIsValid) {
        setCvcIsValid(false);
      }
    }

    onEditing();
  }

  return (
    <Form>
      <CardNumberElementContainer isFocused={cardIsFocused}>
        <CardNumberElement
          onReady={(e) => e.focus()}
          onChange={onCardNumberChange}
          onBlur={() => setCardIsFocused(false)}
          onFocus={() => setCardIsFocused(true)}
          options={{
            placeholder: 'Card Number',
            showIcon: true,
            style: cardElementStyle,
          }}
        />
      </CardNumberElementContainer>
      <Spacer y={2.5} />
      <MonthCVCContainer>
        <ExpElementContainer isFocused={expIsFocused}>
          <CardExpiryElement
            onBlur={() => setIsExpFocused(false)}
            onFocus={() => setIsExpFocused(true)}
            options={{ style: cardElementStyle }}
            onChange={onExpChange}
          />
        </ExpElementContainer>
        <Spacer x={2} />
        <CVCElementContainer isFocused={cvcIsFocused}>
          <CardCvcElement
            onBlur={() => setCvcIsFocused(false)}
            onFocus={() => setCvcIsFocused(true)}
            options={{ style: cardElementStyle }}
            onChange={onCvcChange}
          />
        </CVCElementContainer>
      </MonthCVCContainer>
      <Spacer y={2} />
    </Form>
  );
};

export default PaymentForm;
