import React from 'react';
import styled from 'styled-components/native';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const poweredBySrc = require('./assets/powered-by-stripe.png');

const PoweredByIcon = styled.Image`
  width: 142px;
  height: 32px;
`;

export default () => <PoweredByIcon source={poweredBySrc} />;
