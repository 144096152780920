import React from 'react';
import {
  ItemLabel,
  ItemLabelOrangeLink,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { White } from '../../Constants/Style';
import { TouchableWithoutFeedback } from 'react-native';

interface Props {
  onOpenFullPolicy: () => Promise<void>;
}

export default function PleaseNote({ onOpenFullPolicy }: Props) {
  return (
    <VerticalCenter
      style={{
        backgroundColor: White,
        width: 346,
        padding: 16,
        borderRadius: 20,
      }}>
      <TouchableWithoutFeedback onPress={onOpenFullPolicy}>
        <ItemLabel
          style={{ fontSize: 13, fontWeight: '400', textAlign: 'center' }}>
          {
            'Please note that the above is only a summary - to read the full policy for all details click '
          }
          <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
            here
          </ItemLabelOrangeLink>
        </ItemLabel>
      </TouchableWithoutFeedback>
    </VerticalCenter>
  );
}
