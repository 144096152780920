import { Animated, useWindowDimensions, View, StyleSheet } from 'react-native';
import React, { useRef } from 'react';
import { QuoteScreenScrollView } from '../QuoteScreen/QuoteScreenScrollView';
import { QuoteScreenPriceButton } from '../QuoteScreen/QuotePriceButton';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { Spacer } from '../../Components/SimpleComponents';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import InsuranceTitle from '../QuoteScreen/InsuranceTitle';
import PriceButton from '../../Components/PriceButton';
import { POLICY_TYPE_MONTHLY } from '../../Helper/PolicyHelper';
import { Logger } from '../../Helper/Logger';
import { MediumText } from '../../Components/WaffleText';
import { PayOrQuestions } from '../CyberQuoteScreen/PayOrQuestions';
import WhatIsNotCovered from './WhatsNotCovered';
import PleaseNote from '../QuoteScreen/PleaseNote';
import styled from 'styled-components/native';
import { WaffleLightGrey } from '../../Constants/Style';
import { useWaffleNavigationHeader } from '../../Components/NavHeader';
import PoweredBy from '../QuoteScreen/PoweredBy';
import { IncrementorControlValue, ToggleControlValue } from './models/Controls';
import { isFullWeb } from '../../Helper/DeviceHelper';
import QuoteScreenSectionContainer from '../QuoteScreen/QuoteScreenSectionContainer';
import Accidents from './Accidents';
import CustomizePolicy from './CustomizePolicy';
import Illness from './Illness';
import WellnessPrime from './WellnessPrime';
import WellnessBasic from './WellnessBasic';
import petImages from './utils/pet-images';
import whatIsNotCoveredModels from './models/what-is-not-covered.pet-model';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import { FlexWrapSpacer } from '../QuoteScreen/FlexWrapSpacer';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});

// TODO: this is a bit of hack for layout
const BackgroundGreySection = styled(VerticalCenter)`
  width: 100%;
  padding-bottom: 45px;
  background-color: ${WaffleLightGrey};
`;

interface PetDraftPolicyViewModel {
  annualLimit: number;
  annualLimitControl: IncrementorControlValue;
  copay: number;
  copayControl: IncrementorControlValue;
  deductible: number;
  deductibleControl: IncrementorControlValue;
  hasIllness: boolean;
  hasIllnessControl: ToggleControlValue;
  hasWellnessBasic: boolean;
  hasWellnessBasicControl: ToggleControlValue;
  hasWellnessPrime: boolean;
  hasWellnessPrimeControl: ToggleControlValue;
  isLoading: boolean;
  navigation: StackNavigationProp<RootStackParamList, 'Pet Quote'>;
  onGoToNextPolicyOrCheckout: () => Promise<void>;
  onOpenFullPolicy: () => Promise<void>;
  quotePrice: number;
}

export default function PetQuoteScreenView({
  navigation,
  ...draftPolicy
}: PetDraftPolicyViewModel): React.ReactElement {
  const { height: windowHeight } = useWindowDimensions();
  const currentContainerViewScrollY = useRef(new Animated.Value(0)).current;
  const priceButtonStartingY = useRef(5000);
  const ref = useRef<View>(null);

  useWaffleNavigationHeader(navigation);

  return (
    <View style={styles.container}>
      <QuoteScreenScrollView
        currentContainerViewScrollY={currentContainerViewScrollY}>
        <Spacer x={2} y={2} />

        <QuoteScreenPriceButton
          onPress={draftPolicy.onGoToNextPolicyOrCheckout}
          isUpdating={draftPolicy.isLoading}
          currentContainerViewScrollY={currentContainerViewScrollY}
          startingY={priceButtonStartingY.current}
          windowHeight={windowHeight}
          price={draftPolicy.quotePrice}
        />

        <Spacer y={5} />
        <InsuranceTitle>Pet Insurance</InsuranceTitle>
        <Spacer y={3} />

        <PoweredBy source={petImages.poweredBy} />

        <Spacer y={2.5} />

        <PriceButton
          testID="pet-quote-small-price-button"
          size="large"
          onPress={draftPolicy.onGoToNextPolicyOrCheckout}
          isUpdating={draftPolicy.isLoading}
          price={draftPolicy.quotePrice}
          policyFrequency={POLICY_TYPE_MONTHLY}
        />

        <Spacer y={5} />

        <View
          ref={(r) => (ref.current = r)}
          onLayout={() => {
            if (ref.current) {
              ref.current.measure((x, y, width, height, pageX, pageY) => {
                priceButtonStartingY.current = y;
                Logger(
                  `Measure: x=${x} y=${y} width=${width} height=${height} pageX=${pageX} pageY=${pageY}`
                );
              });
            }
          }}>
          <Spacer y={5} />
        </View>

        <Spacer y={2.5} />

        <MediumText style={{ fontWeight: '400', color: '#919191' }}>
          No hidden fees. Cancel anytime.
        </MediumText>

        <Spacer y={5} />

        <QuoteScreenSectionContainer
          backgroundColor={isFullWeb() ? 'gray' : 'white'}
          title="Your Coverage">
          <Accidents onSamplePolicyOpen={draftPolicy.onOpenFullPolicy} />
          <FlexWrapSpacer />
          <CustomizePolicy
            deductible={draftPolicy.deductible}
            deductibleControl={draftPolicy.deductibleControl}
            copay={draftPolicy.copay}
            copayControl={draftPolicy.copayControl}
            annualLimit={draftPolicy.annualLimit}
            annualLimitControl={draftPolicy.annualLimitControl}
          />
          <FlexWrapSpacer />
          <Illness
            hasIllness={draftPolicy.hasIllness}
            hasIllnessControl={draftPolicy.hasIllnessControl}
          />
        </QuoteScreenSectionContainer>

        <QuoteScreenSectionContainer
          backgroundColor={'white'}
          icon={<CoverageTitleIcon source={petImages.wellness_prevention} />}
          description={
            'Covers actions that help keep your pet healthy. There are no co-pays or deductibles associated with this protection. Total annual maximum benefits for Prime of $450 and $250 for Basic.'
          }
          title="Wellness Prevention">
          <WellnessBasic
            isDisabled={draftPolicy.hasWellnessPrime}
            hasWellnessBasic={draftPolicy.hasWellnessBasic}
            hasWellnessBasicControl={draftPolicy.hasWellnessBasicControl}
          />
          <FlexWrapSpacer />
          <WellnessPrime
            isDisabled={draftPolicy.hasWellnessBasic}
            hasWellnessPrime={draftPolicy.hasWellnessPrime}
            hasWellnessPrimeControl={draftPolicy.hasWellnessPrimeControl}
          />
        </QuoteScreenSectionContainer>
        <WhatIsNotCovered
          onSamplePolicyOpen={draftPolicy.onOpenFullPolicy}
          items={whatIsNotCoveredModels}
        />
        <BackgroundGreySection>
          <PleaseNote onOpenFullPolicy={draftPolicy.onOpenFullPolicy} />
        </BackgroundGreySection>

        <PayOrQuestions />
        <Spacer y={20} />
      </QuoteScreenScrollView>
    </View>
  );
}
