import React, { useEffect, useState } from 'react';
import PaymentForm from './PaymentForm';
import { DataRow, Spacer } from '../../Components/SimpleComponents';
import { HorizontalSpread } from '../../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { PaymentMethodType } from '../../Helper/PaymentHelper.generic';
import SavedCard from './SavedCard';
import { PostalIconInput } from '../../Components/CreateAccount/PostalInput';
import { Logger } from '../../Helper/Logger';
import Sentry from '../../Sentry';

type CardSubmissionFormProps = {
  paymentMethod: PaymentMethodType;
  onSave: (zipcode: string) => Promise<void>;
  onNext: () => Promise<void>;
  startEditing: () => void;
};

const CardSubmissionForm = ({
  startEditing,
  onSave,
  onNext,
  paymentMethod,
}: CardSubmissionFormProps) => {
  const [isEditing, setIsEditing] = useState(true);
  const [cardCanBeSaved, setCardCanBeSaved] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [isFormValid, setFormIsValid] = useState(false);
  const toggleEditing = () => setIsEditing(!isEditing);
  const hasSavedCard = !!paymentMethod?.id;
  // Gives space for cancel button if we have a saved card
  const submitButtonWidth = isEditing && hasSavedCard ? 170 : 269;
  let buttonLabel = 'Submit Payment';

  useEffect(() => {
    if (isFormValid && !cardCanBeSaved && zipCode.length === 5) {
      setCardCanBeSaved(true);
    } else if (!isFormValid && cardCanBeSaved) {
      setCardCanBeSaved(false);
    }
  }, [zipCode, isFormValid, cardCanBeSaved]);

  if (isEditing) {
    buttonLabel = hasSavedCard ? 'Replace Card' : 'Confirm';
  }

  async function onSubmit() {
    try {
      Logger(
        `cardSubmission: onSubmit zip=${JSON.stringify(
          zipCode
        )} isEditing=${isEditing}`
      );
      if (isEditing) {
        await onSave(zipCode);
        await setIsEditing(false);
      } else {
        await onNext();
      }
    } catch (e) {
      // swallow error?
      Logger(`error saving card: ${JSON.stringify(e?.message)}`);

      Sentry.captureException(e);
    }
  }

  return (
    <>
      {isEditing ? (
        <>
          <PaymentForm
            onZipCodeChange={(z) => setZipCode(z)}
            onEditing={startEditing}
            onFormIsValidCheck={setFormIsValid}
          />
          <PostalIconInput
            containerStyle={{ width: 326 }}
            value={zipCode}
            onChangeText={(z) => setZipCode(z)}
            otherProps={{
              testID: 'payment-input-zipcode',
              editable: true,
              enablesReturnKeyAutomatically: true,
              onSubmitEditing: onSubmit,
            }}
          />
          <Spacer y={6.5} />
        </>
      ) : null}
      {!isEditing && hasSavedCard ? (
        <SavedCard
          onPress={async () => {
            await startEditing();
            setIsEditing(true);
            toggleEditing();
          }}
          paymentMethod={paymentMethod}
        />
      ) : null}
      <DataRow style={{ height: 60, marginTop: 20 }}>
        <HorizontalSpread style={{ maxWidth: '100%' }}>
          <WaffleButtonAsync
            testID="payment-submit-button"
            style={{ width: submitButtonWidth }}
            disabled={
              (!isEditing && !hasSavedCard) || (isEditing && !cardCanBeSaved)
            }
            onPress={onSubmit}
            name={buttonLabel}
          />

          {hasSavedCard && isEditing ? (
            <>
              <Spacer x={2} />
              <WaffleButtonAsync
                style={{ width: 170 }}
                onPress={async () => toggleEditing()}
                name={'Cancel'}
              />
            </>
          ) : null}
        </HorizontalSpread>
      </DataRow>
    </>
  );
};

export default CardSubmissionForm;
