import { CancelPolicyInput } from '../models';
import { Logger } from '../../../Helper/Logger';
import { ProductRenters } from '../../../Constants/Products';
import GetGraphqlRoot from '../../../Helper/GetGraphqlRoot';
import { cachedTokenReactiveVar } from '../../../GraphQL/Waffle/ApolloClientBuilder';
import { PolicyActionResponse } from './types';

export async function cancelPolicy({
  policy,
}: CancelPolicyInput): Promise<PolicyActionResponse> {
  switch (policy.productId) {
    case ProductRenters: {
      const url = `${GetGraphqlRoot().v2}/policies/${policy.id}/cancel`;
      const token = cachedTokenReactiveVar(); // todo : this can be stale!
      const postResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });

      const j = await postResponse.json();

      Logger(
        `Cancelling policy response=${postResponse.status} message=${
          postResponse.statusText
        } responseBody=${j} policy=${JSON.stringify(policy)}`
      );

      return { ...j, status: postResponse.status };
    }

    default:
      Logger(`Can't cancel policy for product ${policy.productId}`);
      return { status: 0, isSuccess: false };
  }
}
