import { Animated } from 'react-native';
import styles from '../../Constants/Style';
import React from 'react';

type Props = {
  children: React.ReactNode;
  currentContainerViewScrollY: Animated.Value;
};

export const QuoteScreenScrollView = ({
  children,
  currentContainerViewScrollY,
}: Props) => {
  return (
    <Animated.ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={[styles.fullWidth, styles.fullHeightMinusHeader]}
      contentContainerStyle={styles.verticalPackedCenter}
      scrollEventThrottle={4}
      onScroll={Animated.event(
        [
          {
            nativeEvent: { contentOffset: { y: currentContainerViewScrollY } },
          },
        ],
        { useNativeDriver: true }
      )}>
      {children}
    </Animated.ScrollView>
  );
};
