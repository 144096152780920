import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import React, { useCallback, useLayoutEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
  CREATE_OR_RESUME_DRAFT_POLICIES,
  GET_LOCAL_USER,
  GET_PRODUCTS,
} from '../GraphQL/Waffle/Queries';
import {
  HeaderBackButton,
  StackHeaderLeftButtonProps,
  StackScreenProps,
} from '@react-navigation/stack';
import {
  CyberQuoteScreenName,
  HomeScreenName,
  LifeApplicationScreenName,
  LifeOfferScreenName,
  LifeQuoteQuestionsScreenName,
  LifeQuoteScreenName,
  ProductChatScreenName,
  RootStackParamList,
} from '../../screen-config';
import {
  CreateOrResumeDraftPolicies,
  CreateOrResumeDraftPoliciesVariables,
  GetProducts,
  GetProductsVariables,
  LocalUser,
} from '../../../operation-result-types';
import { Logger } from '../Helper/Logger';
import { WaffleDarkBlue, White } from '../Constants/Style';
import { ChatHeaderButton } from '../Components/ChatHeaderButton';
import { IsCyberOnly, IsLifeOnly } from '../Helper/PolicyHelper';
import {
  USER_STATE_PRODUCT_CHAT,
  USER_STATE_SUGGESTED_COVERAGE,
} from '../Helper/NavigationService';
import { ScrollView, Spacer } from '../Components/SimpleComponents';
import { ProductChooser } from '../Components/ProductChooser/ProductChooser';
import { ContentUnified, ScrollingBlock } from '../Components/ContentShared';
import {
  UpdateUserStateOnServer,
  CompleteUserState,
  RefreshUserState,
  BuildVersionAndPlatform,
} from '../Helper/UserHelpers';
import {
  ProductChooserHeader,
  ProductsContainer,
} from '../Components/ProductChooser/ProductChooserComponents';
import {
  ProductDummyNative,
  ProductTouchableDummyWeb,
} from '../Components/ProductToggleButton';
import { VerticalCenter } from '../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { OrangeHeaderImage } from '../Components/HeaderNavTitle.web';
import { LoadingUnified } from '../Components/LoadingUnified';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import { useAuthContext } from './AuthProvider';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';
import { getLifePolicyMetaDataAsync } from '../../lib/life';

export const onReFetch = async (client) => {
  Logger(`refetching products`);
  await client.query({
    query: GET_PRODUCTS,
    fetchPolicy: 'network-only',
    variables: BuildVersionAndPlatform(),
  });
};

const DummyProductsNativeScreen = () => (
  <ScrollView>
    <ProductChooserHeader selectedCount={0} />

    <ProductsContainer>
      <ProductDummyNative />
      <ProductDummyNative />
      <ProductDummyNative />

      <ProductDummyNative />
      <ProductDummyNative />
      <ProductDummyNative />

      <ProductDummyNative />
      <ProductDummyNative />
      <ProductDummyNative />
    </ProductsContainer>

    <Spacer y={3} />

    <VerticalCenter>
      <WaffleButtonAsync
        disabled={true}
        onPress={async () => {
          /** noop **/
        }}
        name={'Next'}
      />

      <Spacer y={1.5} />
    </VerticalCenter>
  </ScrollView>
);

const DummyProductsWebScreen = ({ title }) => (
  <ContentUnified
    title={title}
    titleStyle={{ top: 18 }}
    image={headerImage}
    ChildrenWrapper={ScrollingBlock}>
    <ProductChooserHeader selectedCount={0} />
    <ProductsContainer>
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
      <ProductTouchableDummyWeb />
    </ProductsContainer>
  </ContentUnified>
);

const DummyProductsScreen = ({ title }) => {
  if (isNativeOrMobileWeb()) {
    return <DummyProductsNativeScreen />;
  } else {
    return <DummyProductsWebScreen title={title} />;
  }
};

const title = 'Select Your Coverage';
const headerImage = OrangeHeaderImage;

const ProductChooserScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Product Chooser'>) => {
  const client = useApolloClient();
  const userTracking = useUserTracking();
  const { loading, error, data } = useQuery<GetProducts, GetProductsVariables>(
    GET_PRODUCTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: BuildVersionAndPlatform(),
    }
  );
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const [createOrResumeDraftPolicies] = useMutation<
    CreateOrResumeDraftPolicies,
    CreateOrResumeDraftPoliciesVariables
  >(CREATE_OR_RESUME_DRAFT_POLICIES);
  const { isGuest } = useAuthContext();

  const cleanupState = useCallback(() => {
    Logger('cleaning up and re-fetching user');
    RefreshUserState(client).then((r) => Logger('Done re-fetching user'));
    onReFetch(client);
  }, []);

  function onBackButtonPress() {
    CompleteUserState(client).then(() => navigation.navigate(HomeScreenName));
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackTitle: 'Home',
      headerLeft: (props: StackHeaderLeftButtonProps) =>
        !isGuest && (
          <HeaderBackButton
            {...props}
            tintColor={WaffleDarkBlue}
            onPress={onBackButtonPress}
          />
        ),
      headerRight: (props: StackHeaderLeftButtonProps) => (
        <ChatHeaderButton {...props} />
      ),
      headerTitle: () => <></>,
      headerStyle: {
        backgroundColor: White,
        borderBottomWidth: 0,
        shadowColor: 'transparent',
      },
      gestureEnabled: false,
      headerShown: isNativeOrMobileWeb(),
    });
  }, [navigation]);

  // Clean up state here instead of in cancel.  That way if user restarts app
  //   after onboarded and having started adding, they won't end up in a bad
  //   state
  useFocusEffect(cleanupState);

  if (loading && !data) {
    return <DummyProductsScreen title={title} />;
  }

  /* native */
  // if (error) {
  //   return <LoadingScreen errors={error?.message} />;
  // }
  /* web */
  if (error) {
    return (
      <LoadingUnified
        title={title}
        image={headerImage}
        errors={error?.message}
        addingProduct={true}
      />
    );
  }
  /*end*/

  const products = data?.products ?? [];

  const handleNext = async (toggles) => {
    // If new draft policy, let's save the draft policies
    const isPressed = (product) => toggles?.[product.id] ?? false;
    const selectedProducts = products.filter((product) => isPressed(product));

    userTracking?.publishProductsAdded(selectedProducts);

    await createOrResumeDraftPolicies({
      variables: { input: selectedProducts.map((p) => p.id) },
    });

    if (IsCyberOnly(selectedProducts) && user?.email) {
      // If only Cyber is selected, we just skip to the Cyber quote screen.
      // note: here is where we could potentially skip
      await UpdateUserStateOnServer(client, USER_STATE_SUGGESTED_COVERAGE);
      navigation.navigate(CyberQuoteScreenName);
      return;
    }

    if (IsLifeOnly(selectedProducts)) {
      const policy = await getLifePolicyMetaDataAsync(client);
      await UpdateUserStateOnServer(client, USER_STATE_SUGGESTED_COVERAGE);

      if (policy.OfferId) {
        navigation.navigate(LifeOfferScreenName);
        return;
      } else if (policy.ExternalApplicationId) {
        navigation.navigate(LifeApplicationScreenName);
        return;
      } else if (policy.ExternalQuoteId) {
        navigation.navigate(LifeQuoteScreenName);
        return;
      }

      navigation.navigate(LifeQuoteQuestionsScreenName);
      return;
    }

    await UpdateUserStateOnServer(client, USER_STATE_PRODUCT_CHAT);
    await navigation.navigate(ProductChatScreenName);
  };

  return (
    <ContentUnified
      titleStyle={{ alignItems: 'center' }}
      title={title}
      image={headerImage}
      addingProduct={true}
      ChildrenWrapper={ScrollingBlock}>
      <ProductChooser
        products={products}
        onCancel={async () => await navigation.navigate(HomeScreenName)}
        onNext={handleNext}
      />
    </ContentUnified>
  );
};

export default ProductChooserScreen;
