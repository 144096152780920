import { ImageSource } from 'react-native-vector-icons/Icon';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { Image } from 'react-native';
import React from 'react';

type Props = {
  source: ImageSource;
  width?: number;
  height?: number;
};
export default function PoweredBy({
  source,
  width = 216,
  height = 67,
}: Props): React.ReactElement {
  return (
    <VerticalCenter>
      <Image
        source={source}
        style={{
          width,
          height,
        }}
        resizeMode={'contain'}
      />
    </VerticalCenter>
  );
}
