import React from 'react';
import {
  GetDraftUserPolicies,
  UpdateCoverageAndReprice,
  UpdateCoverageAndRepriceVariables,
} from '../../../../../operation-result-types';
import {
  DRAFT_POLICIES,
  UPDATE_COVERAGE_AND_REPRICE,
} from '../../../GraphQL/Waffle/Queries';
import { DraftPolicyToStateMapper } from './DraftPolicyToStateMapper';
import { PolicyFinder } from './PolicyFinder';
import AsyncState from './AsyncState';
import { ApolloClient } from '@apollo/client';

type Props<T> = {
  client: ApolloClient<object>;
  coverageId: string;
  coverageLimit: string;
  setState: React.Dispatch<React.SetStateAction<T>>;
  mapDraftPolicyToState: DraftPolicyToStateMapper<T>;
  findPolicy: PolicyFinder;
};

export default async function updateCoverageValueAndSetStateWithMapperAsync<
  T extends AsyncState
>({
  client,
  coverageId,
  coverageLimit,
  setState,
  mapDraftPolicyToState,
  findPolicy,
}: Props<T>) {
  setState((existing) => ({ ...existing, isLoading: true }));

  await client.mutate<
    UpdateCoverageAndReprice,
    UpdateCoverageAndRepriceVariables
  >({
    mutation: UPDATE_COVERAGE_AND_REPRICE,
    variables: { input: { id: coverageId, coverage_limit: coverageLimit } },
  });

  const { data } = await client.query<GetDraftUserPolicies>({
    query: DRAFT_POLICIES,
    fetchPolicy: 'network-only',
  });

  const policy = findPolicy(data?.draftUserPolicies);

  setState((prevState) => {
    const mappedState = mapDraftPolicyToState(policy, prevState);
    return {
      ...mappedState,
      isLoading: false,
    };
  });
}
