import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList, WebScreenName } from '../../../screen-config';
import { GetTokenFromAsync } from '../../Helper/UserHelpers';
import GetGraphqlRoot from '../../Helper/GetGraphqlRoot';
import { ProductRenters } from '../../Constants/Products';
import { IsCyberPolicy } from '../../Helper/PolicyHelper';
import { ViewPolicyDocumentButton } from './ViewPolicyDocumentButton';
import { CancelPolicyButton } from './CancelPolicyButton';
import React from 'react';
import { Platform } from 'react-native';
import { NavigationGoHomeButton } from '../../Components/SimpleComponents';
import CustomLinking from '../../Helper/CustomLinking';
import { Policy, PolicyProps } from '../../../component-config';
import { isPolicyActive } from './utils';

// Some voodoo from interwebs!
function showFile(blob) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // @ts-ignore TODO: fix this correctly
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore TODO: fix this correctly
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  window.open(data);

  // Use the below lines to download the PDF instead of opening it in a window.
  //
  //   var link = document.createElement('a');
  //   link.href = data;
  //   link.download = 'file.pdf';
  //   link.click();
  //

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

const showCyberPolicyDocument = async (
  token: string,
  policy: Policy,
  navigation: StackNavigationProp<RootStackParamList>
) => {
  const uri = `${GetGraphqlRoot().media}/policies/latestDocument`;
  const title = `${policy.productId} Latest Policy`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'X-Policy-ID': policy.id,
  };

  if (Platform.OS === 'web') {
    // await CustomLinking.openURL(uri);
    await fetch(uri, {
      method: 'GET',
      headers,
    })
      .then((r) => r.blob())
      .then(showFile);
  } else {
    await navigation.navigate(WebScreenName, {
      uri,
      title,
      headers,
      headerRight: NavigationGoHomeButton,
    });
  }
};

const showDocument = async (
  productId: string,
  uri: string,
  navigation: StackNavigationProp<RootStackParamList>
) => {
  const title = `${productId} Latest Policy`;

  if (Platform.OS === 'web') {
    await CustomLinking.openURL(uri, '_blank');
  } else {
    navigation.navigate(WebScreenName, {
      uri,
      title,
      headerRight: NavigationGoHomeButton,
    });
  }
};

export const PolicyDescriptionActions = ({ policy }: PolicyProps) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const onViewPolicyDocumentAsync = async () => {
    const token = await GetTokenFromAsync();
    await showCyberPolicyDocument(token, policy, navigation);
  };
  const onViewWaffleDocument = async (id: string) => {
    const token = await GetTokenFromAsync();

    await showDocument(
      policy.productId,
      `${GetGraphqlRoot().media}/media/${id}?token=${token}`,
      navigation
    );
  };
  const isCancellable = false; // policy.productId === ProductRenters;
  const isActive = isPolicyActive(policy);

  if (IsCyberPolicy(policy)) {
    return (
      <ViewPolicyDocumentButton
        name={'View Policy Document'}
        onPress={onViewPolicyDocumentAsync}
      />
    );
  }

  return (
    <>
      {policy.externalPolicy?.COIDocumentId && (
        <ViewPolicyDocumentButton
          name={'View COI'}
          onPress={async () =>
            await onViewWaffleDocument(policy.externalPolicy?.COIDocumentId)
          }
        />
      )}

      {policy.externalPolicy?.PolicyDocumentURL && (
        <ViewPolicyDocumentButton
          name={'View Policy Document'}
          onPress={async () => {
            await showDocument(
              policy.productId,
              policy?.externalPolicy?.PolicyDocumentURL,
              navigation
            );
          }}
        />
      )}

      {policy.externalPolicy?.DescriptionOfCoverageURL && (
        <ViewPolicyDocumentButton
          name={'Description of Coverage'}
          onPress={async () => {
            await showDocument(
              policy.productId,
              policy?.externalPolicy?.DescriptionOfCoverageURL,
              navigation
            );
          }}
        />
      )}

      {isCancellable && <CancelPolicyButton policy={policy} />}
    </>
  );
};
