import { useQuery } from '@apollo/client';
import { GetFinalizedUserPolicies } from '../../../../operation-result-types';
import { USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { BuildVersionAndPlatform } from '../../Helper/UserHelpers';
import React, { useState } from 'react';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { POLICY_STATUS_FINALIZED } from '../../Helper/PolicyHelper';
import Popup from '../../Components/Popup';
import { PopupTitle } from '../../Components/WaffleText';
import { UserWarning } from '../../Components/DesignPolicy/UserWarning';
import { Spacer } from '../../Components/SimpleComponents';
import { HorizontalEvenly } from '../../Components/SimpleLayouts';
import { cancelPolicy } from './utils';
import { PolicyProps } from '../../../component-config';

type State = {
  status: 'prompt' | 'cancelling' | 'done';
  title: string;
  body: string;
};

export const CancelPolicyButton = ({ policy }: PolicyProps) => {
  const { refetch } = useQuery<GetFinalizedUserPolicies>(USER_POLICIES, {
    fetchPolicy: 'network-only',
    variables: { input: BuildVersionAndPlatform() },
  });
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState<State>({
    status: 'prompt',
    title: 'Cancel policy?',
    body: 'Are you sure you want to cancel your policy? It will be effective immediately.',
  });

  return (
    <>
      <WaffleButtonAsync
        name={'Cancel Policy'}
        style={{
          marginTop: 10,
          width: '90%',
          borderRadius: 7,
          backgroundColor: '#FFFFFF',
          borderWidth: 1,
          borderColor: '#AAAAAA',
        }}
        pressedName={'Fetching policy...'}
        textStyle={{
          color: '#000000',
        }}
        disabled={policy.status !== POLICY_STATUS_FINALIZED}
        onPress={async () => setPopup(true)}
      />
      <Popup visible={popup} onDismiss={() => setPopup(false)}>
        <PopupTitle>{state.title}</PopupTitle>

        <UserWarning style={{ textAlign: 'center' }}>{state.body}</UserWarning>

        <Spacer y={3} />

        <HorizontalEvenly style={{ width: '100%' }}>
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={state.status === 'prompt' ? 'Confirm' : 'Close'}
            pressedName={'Working'}
            onPress={async () => {
              if (state.status === 'done') {
                await refetch();
                setPopup(false);
              } else {
                await setState((st) => ({
                  ...st,
                  status: 'cancelling',
                  title: 'Cancel policy',
                  body: 'Cancelling...',
                }));
                const { isSuccess, messages, errors } = await cancelPolicy({
                  policy,
                });

                if (isSuccess) {
                  await setState((st) => ({
                    ...st,
                    status: 'done',
                    title: 'Policy cancelled',
                    body: messages.map((msg) => msg.message).join('\n'),
                  }));
                } else {
                  await setState((st) => ({
                    ...st,
                    status: 'done',
                    title: 'Error cancelling',
                    body:
                      'We encountered the below error(s) while trying to cancel your policy.\n' +
                      'Please contact support for further assistance.\n\n' +
                      errors.map((err) => err.error).join('\n'),
                  }));
                }
              }
            }}
          />
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={'Cancel'}
            disabled={state.status !== 'prompt'}
            onPress={async () => setPopup(false)}
          />
        </HorizontalEvenly>
      </Popup>
    </>
  );
};
