import { StripeElementStyle } from '@stripe/stripe-js';

const cardElementStyle: StripeElementStyle = {
  base: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
    fontSize: '18px',
    iconColor: 'rgba(250, 142, 70, 1)',
    ':focus': {
      backgroundColor: 'white',
    },
    '::placeholder': {
      color: 'rgba(205, 207, 208, 1)',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
  },
  invalid: {
    color: 'red',
    iconColor: 'red',
  },
};

export default cardElementStyle;
