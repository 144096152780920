import styled from 'styled-components/native';
import { ImageSourcePropType, Platform } from 'react-native';
import WaffleText from '../../Components/WaffleText';
import React from 'react';
import { Spacer } from '../../Components/SimpleComponents';

const LabeledIconContainer = styled.View`
  width: 110px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.Image<{ isActive?: boolean }>`
  width: 57px;
  height: 57px;
  ${(p) =>
    p.isActive
      ? ''
      : Platform.OS === 'web'
      ? 'opacity: 0.3; filter: grayscale(100%);'
      : 'opacity: 0.3;'}
`;

const LabelText = styled(WaffleText)<{ isActive: boolean }>`
  font-size: 13px;
  text-align: center;
  ${(p) =>
    p.isActive
      ? ''
      : Platform.OS === 'web'
      ? 'opacity: 0.3; filter: grayscale(100%);'
      : 'opacity: 0.3;'}
`;

type LabeledIconProps = {
  source: ImageSourcePropType;
  label: string;
  isActive?: boolean;
};
const LabeledIcon = function LabeledIcon({
  source,
  label,
  isActive = true,
}: LabeledIconProps): React.ReactElement {
  return (
    <LabeledIconContainer>
      <Icon source={source} isActive={isActive} />
      <Spacer x={0.5} y={0.5} />
      <LabelText isActive={isActive}>{label}</LabelText>
    </LabeledIconContainer>
  );
};

export default LabeledIcon;
