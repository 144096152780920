export const LEVEL = 'Level';
export const DEDUCTIBLE = 'Deductible';
export const COPAY = 'Copay';
export const ANNUAL_LIMIT = 'AnnualLimit';
export const WELLNESS = 'Wellness';

export type CoverageId =
  | 'Level'
  | 'Deductible'
  | 'Copay'
  | 'AnnualLimit'
  | 'Wellness';

export type WellnessChoiceValues = 'Low' | 'High' | 'None';

type WellnessValuesObject = {
  basic: WellnessChoiceValues;
  prime: WellnessChoiceValues;
  none: WellnessChoiceValues;
};

export const WellnessValues: WellnessValuesObject = {
  basic: 'Low',
  prime: 'High',
  none: 'None',
};

export type IllnessToggleValues = 'Level1' | 'Level2';

type IllnessValuesObject = {
  level1: IllnessToggleValues;
  level2: IllnessToggleValues;
};

export const IllnessValues: IllnessValuesObject = {
  level1: 'Level1',
  level2: 'Level2',
};
