import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  isPetPromoEligible,
  PromoCyber,
  PromoPet,
  PromotionTypes,
} from '../../Components/Promos';
import {
  CyberQuoteScreenName,
  ProductChatScreenName,
  RootStackParamList,
} from '../../../screen-config';
import { useNavigation } from '@react-navigation/native';
import {
  CreateDraftPolicies,
  CreateDraftPoliciesVariables,
} from '../../../../operation-result-types';
import { CREATE_DRAFT_POLICIES } from '../../GraphQL/Waffle/Queries';
import { Policies, Products } from '../../../component-config';
import { StepMessage } from '../../Components/StepMessage';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { FLAG_NAMES, useFlagBooleanValue } from '../../../lib/feature-flags';

type Props = {
  policies: Policies;
  products?: Products;
  onPress: () => Promise<any>;
};

function promoSelector(policies: Policies, products: Products): PromoType {
  const isPetEligible = isPetPromoEligible(policies, products);

  if (isPetEligible) {
    return 'pet';
  }

  return 'none';
}

export const Promo = ({ products, policies, onPress }: Props) => {
  const [createDraftPolicies] = useMutation<
    CreateDraftPolicies,
    CreateDraftPoliciesVariables
  >(CREATE_DRAFT_POLICIES);
  const [promo, setPromo] = useState<PromoType>('none');
  const navigation = useNavigation();
  const userTracking = useUserTracking();
  const redirectToPetLandingPage = useFlagBooleanValue(
    FLAG_NAMES.redirect_to_pet_landing_page,
    false
  );
  const title =
    products?.length === 1 && products?.[0]?.id === 'Renters'
      ? `Congratulations! You're now protected with Renter's Insurance!`
      : `Congratulations! You're now protected through Waffle!`;

  useEffect(() => {
    // Only pick a promo once (for now).  Avoids flickering as we exit.
    if (promo === 'none') {
      setPromo(promoSelector(policies, products));
    }
  }, [promo, policies, products]);

  async function onPromo(
    targetedProductId: PromotionTypes,
    screenName: keyof RootStackParamList
  ) {
    if (targetedProductId === 'Pet') {
      const experimentName = 'pet CTA to LP';
      const experimentId = 'petCTAToLP_1-8-2022';
      if (redirectToPetLandingPage) {
        userTracking?.publishExperimentViewed({
          experimentName,
          experimentId,
          variationName: 'B',
          variationId: 'B',
        });
        window.open(
          'https://go.trywaffle.com/pet?utm_source=waffle-app&utm_medium=promoted&utm_campagin=cs-1',
          '_self'
        );
        return;
      }
      userTracking?.publishExperimentViewed({
        experimentId,
        experimentName,
        variationName: 'A',
        variationId: 'A',
      });
    }
    await createDraftPolicies({
      variables: { input: [targetedProductId] },
    });

    userTracking?.publishPromotionClicked(targetedProductId);

    navigation.navigate(screenName);
  }

  const onCyber = async () => await onPromo('Cyber', CyberQuoteScreenName);

  const onPet = async () => {
    await onPromo('Pet', ProductChatScreenName);
  };

  switch (promo) {
    case 'cyber':
      userTracking?.publishPromotionViewed('Cyber');
      return <PromoCyber onPress={onPress} onPromo={onCyber} />;

    case 'pet':
      userTracking?.publishPromotionViewed('Pet');
      return <PromoPet onPromo={onPet} onPress={onPress} />;

    case 'none':
      return <StepMessage text={title} onPress={onPress} />;
  }
};
