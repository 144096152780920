import { PaymentMethodType } from '../../Helper/PaymentHelper.generic';
import { TouchableRectangularButton } from '../../Components/PaymentInfo/PaymentInfoComponents';
import { CardButton } from '../../Components/PaymentInfo/CardButton';
import React from 'react';

type CardSavedProps = {
  onPress: () => Promise<void>;
  paymentMethod: PaymentMethodType;
};

const SavedCard = ({ onPress, paymentMethod }: CardSavedProps) => {
  return (
    <>
      <TouchableRectangularButton
        testID="touchableCardSavedBtn"
        activeOpacity={0.35}
        onPress={onPress}>
        <CardButton card={paymentMethod?.card} />
      </TouchableRectangularButton>
    </>
  );
};

export default SavedCard;
