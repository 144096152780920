import { useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import {
  DRAFT_POLICIES,
  GET_LOCAL_USER_ID,
  GET_USER_MESSAGES,
  POPULATE_USER_POLICY_ANSWERS,
  PROCESS_USER_RESPONSE,
  UPDATE_USER_STATE,
} from '../GraphQL/Waffle/Queries';
import { myMessages, processMessages } from '../Components/Chat/ChatHelpers';
import WaffleChat from '../Components/WaffleChat';
import { USER_STATE_PRODUCT_CHOOSER } from '../Helper/NavigationService';
import { timeout } from '../Helper/MiscFunctions';
import { ChatHeaderButton } from '../Components/ChatHeaderButton';
import { Image } from '../Components/SimpleComponents';
import { LoadingUnified } from '../Components/LoadingUnified';
import {
  BottomSpacer,
  ContentUnified,
  OnlyBlock,
} from '../Components/ContentShared';
import {
  StackHeaderLeftButtonProps,
  StackScreenProps,
} from '@react-navigation/stack';
import {
  CyberQuoteScreenName,
  PetQuoteScreenName,
  ProductChooserScreenName,
  RentersQuoteScreenName,
  RootStackParamList,
  SuggestedCoverageScreenName,
} from '../../screen-config';
import {
  GetDraftUserPolicies,
  GetLocalUserId,
  GetUserMessages,
  PopulateUserPolicyAnswers,
  ProcessResponse,
  ProcessResponseVariables,
  UpdateUserState,
  UpdateUserStateVariables,
} from '../../../operation-result-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isFullWeb, isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import { VerticalCenter } from '../Components/SimpleLayouts';
import {
  HasCyberPolicy,
  HasPetPolicy,
  HasRentersPolicy,
  HasTravelPolicy,
} from '../Helper/PolicyHelper';
import { LinearProgress } from 'react-native-elements';
import { Policies } from '../../component-config';
import { WaffleOrange } from '../Constants/Style';
import { useAuthContext } from './AuthProvider';
import { VisualViewport } from '../Components/VisualViewport';
import { Platform } from 'react-native';
import WaffleTextVCS from '../Components/WaffleTextVCS';

// Process messages locally.   Eventually, this will be deprecated, but
// now we use it to bail early from the chat interaction.

const title = `Let's get more specific`;

interface SimpleMessage {
  text: string;
  value?: string;
}

export const NavigateToProduct = () => {
  const [updateUserState] = useMutation<
    UpdateUserState,
    UpdateUserStateVariables
  >(UPDATE_USER_STATE);
  const navigation = useNavigation();

  const doNavigateToProduct = async () => {
    // Backend will ignore this if the user is already completed, so it's
    // safe to run.
    await updateUserState({
      variables: {
        state: USER_STATE_PRODUCT_CHOOSER,
      },
    });

    navigation.navigate(ProductChooserScreenName);
  };

  return <Icon name={'chevron-left'} size={42} onPress={doNavigateToProduct} />;
};

const newMessages = (userId, messages) => {
  return messages.map((message) => ({
    _id: 1,
    // text: 'Hello developer',
    // createdAt: new Date(),
    user: {
      _id: userId,
      name: 'React Native',
      avatar: 'https://placeimg.com/140/140/any',
    },
    ...message,
  }));
};

const approximateProgress = (
  userId: string,
  isGuest: boolean,
  policies: Policies,
  messages: any[]
) => {
  const mine = myMessages(messages, userId);
  const answerCount = mine?.length || 0;
  let expectedMax = 5;

  if (HasRentersPolicy(policies)) {
    expectedMax = 3 + (isGuest ? 1 : 0);
  } else if (HasPetPolicy(policies)) {
    if (answerCount >= 1) {
      const numPets = parseInt(mine[0].text);

      expectedMax = 1 + numPets * 5 + (isGuest ? 2 : 0);
    } else {
      expectedMax = 8;
    }
  } else if (HasTravelPolicy(policies)) {
    // todo : figure out the number of travelers and adjust accordingly
    // 5 + count-specific + 2 (if guest)
    expectedMax = 5 + 2 + (isGuest ? 2 : 0);
  } else if (HasCyberPolicy(policies)) {
    expectedMax = 1;
  }

  // Logger(
  //   `expectedMax: ${expectedMax} answerCount=${answerCount} mine=${JSON.stringify(
  //     messages
  //   )}`
  // );

  return Math.min((1.0 * answerCount) / expectedMax, 1.0);
};

const ProductChatScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Product Chat'>) => {
  const {
    data: { userId },
  } = useQuery<GetLocalUserId>(GET_LOCAL_USER_ID);
  const [tempMessage, setTempMessage] = useState([]);
  const { data, loading, error, refetch } = useQuery<GetUserMessages>(
    GET_USER_MESSAGES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [sendChat] = useMutation<ProcessResponse, ProcessResponseVariables>(
    PROCESS_USER_RESPONSE,
    {
      refetchQueries: [GET_USER_MESSAGES],
    }
  );
  const [populatePolicyAnswers] = useMutation<PopulateUserPolicyAnswers>(
    POPULATE_USER_POLICY_ANSWERS
  );
  const { data: dataPolicies, refetch: refetchDraftPolicies } =
    useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
      fetchPolicy: 'network-only',
    });
  const { isGuest } = useAuthContext();
  const hasCyber = HasCyberPolicy(dataPolicies?.draftUserPolicies ?? []);
  const hasRenter = HasRentersPolicy(dataPolicies?.draftUserPolicies ?? []);
  const hasPet = HasPetPolicy(dataPolicies?.draftUserPolicies ?? []);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Waffle',
      headerTitle: () => (
        <VerticalCenter style={{ width: '100%' }}>
          <Image
            source={require('../../assets/images/waffleLogoTitle.png')}
            style={{ width: 118, height: 30 }}
          />
        </VerticalCenter>
      ),
      headerLeft: () => <NavigateToProduct />,
      headerRight: (props: StackHeaderLeftButtonProps) => (
        <ChatHeaderButton {...props} />
      ),
      headerShown: isNativeOrMobileWeb(),
      headerStyle: {
        backgroundColor: 'white',
      },
      gestureEnabled: false,
    });
  }, [navigation]);
  useEffect(() => {
    setTempMessage([]);
  }, [data?.userMessages?.length]);

  useFocusEffect(
    useCallback(() => {
      refetch().then(() => refetchDraftPolicies().then());
    }, [])
  );

  if (loading || error) {
    return (
      <LoadingUnified
        title={title}
        titleStyle={{ top: 10 }}
        errors={error?.message}
        addingProduct={true}
      />
    );
  }

  const lastActionOrQuickReply =
    !!data.userMessages[0]?.action || data.userMessages[0]?.choices.length > 0;
  const onDone = async () => await populatePolicyAnswers();

  // LoggerObject(`last message:`, data?.userMessages?.[0]);

  const onSend = async (
    messages: SimpleMessage[],
    sendChat: any,
    userId: any,
    onDone: () => void
  ) => {
    // xxx: This should probably be removed...
    if (messages[0].text === 'Done') {
      // todo : deprecate!
      await navigation.navigate(SuggestedCoverageScreenName);
    }

    const {
      data: { processResponse },
    } = await sendChat({
      variables: {
        userId: userId,
        answer: messages[0].text,
        value: messages[0]?.value,
      },
    });

    if (
      processResponse.status === 'Success' ||
      processResponse.status === 'Complete'
    ) {
      await timeout(2500);
      if (onDone) {
        await onDone();
      }

      if (hasCyber) {
        navigation.navigate(CyberQuoteScreenName);
      } else if (hasRenter) {
        navigation.navigate(RentersQuoteScreenName);
      } else if (hasPet) {
        navigation.navigate(PetQuoteScreenName);
      } else {
        await navigation.navigate(SuggestedCoverageScreenName);
      }
    }
  };

  const _messages = processMessages(data.userMessages);
  const _newMessages = newMessages(userId, tempMessage);
  const messages = _messages.concat(_newMessages);

  return (
    <ContentUnified
      title={title}
      titleStyle={{ alignItems: 'center' }}
      ChildrenWrapper={OnlyBlock}
      showNavHeader={false}
      addingProduct={true}>
      <VisualViewport style={{ height: '100%' }}>
        <WaffleTextVCS
          style={{
            fontSize: 30,
            position: 'relative',
            top: -45,
          }}>
          Let&apos;s Get Specific
        </WaffleTextVCS>
        {!isFullWeb() && (
          <LinearProgress
            color={WaffleOrange}
            variant={'determinate'}
            value={approximateProgress(
              userId,
              isGuest,
              dataPolicies?.draftUserPolicies ?? [],
              messages
            )}
            style={{
              ...(isFullWeb()
                ? {
                    position: 'relative',
                    top: -15,
                  }
                : {}),
            }}
          />
        )}
        <WaffleChat
          keyboardShouldPersistTaps={'never'}
          isKeyboardInternallyHandled={false}
          messages={[...messages]}
          onSend={async (messages: any) => {
            setTempMessage(messages);
            await onSend(messages, sendChat, userId, onDone);
          }}
          onQuickReply={async (qr: any) => {
            const newMessages = qr.map((v: any) => ({
              text: v.value,
            }));

            setTempMessage(newMessages);
            await onSend(newMessages, sendChat, userId, onDone);
          }}
          userId={userId}
          onPicker={async (text: string, value = null) =>
            await onSend([{ text, value }], sendChat, userId, onDone)
          }
          textInputProps={{
            editable: !lastActionOrQuickReply,
          }}
        />

        {Platform.OS === 'ios' && <BottomSpacer />}
      </VisualViewport>
    </ContentUnified>
  );
};

export default ProductChatScreen;
