import React from 'react';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import QuoteScreenSectionContainer from '../QuoteScreen/QuoteScreenSectionContainer';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import { WhatIsNotCoveredModel } from '../QuoteScreen/models/what-is-not-covered.model';
import petImages from './utils/pet-images';
import { ItemLabelOrangeLink } from '../../Components/DesignPolicy/SuggestedCoverageShared';
import { TouchableWithoutFeedback } from 'react-native';

const Container = styled.View`
  width: 332px;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
`;

type WhatIsNotCoveredProps = {
  items: WhatIsNotCoveredModel[];
  onSamplePolicyOpen: () => Promise<void>;
};

export default function WhatIsNotCovered({
  items,
  onSamplePolicyOpen,
}: WhatIsNotCoveredProps): React.ReactElement {
  const lastItem = items[items.length - 1];
  return (
    <QuoteScreenSectionContainer
      backgroundColor="gray"
      title="What's Not Covered...">
      <Container key={'Pre-Existing Conditions'}>
        <CoverageTitleIcon source={petImages.preExisting} />
        <Spacer x={2} y={2} />
        <WaffleText
          style={{
            fontSize: 20,
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {'Pre-Existing Contions'}
        </WaffleText>
        <Spacer x={2} y={2} />
        <CoverageCardDescription>
          Pre-existing conditions are not covered. They are defined as occurring
          before coverage begins or during a waiting period. Learn more{' '}
          <TouchableWithoutFeedback onPress={onSamplePolicyOpen}>
            <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
              here.
            </ItemLabelOrangeLink>
          </TouchableWithoutFeedback>
        </CoverageCardDescription>
        <Spacer x={2} y={4} />
      </Container>
      {items.slice(1, 4).map((model) => (
        <Container key={model.label}>
          <CoverageTitleIcon source={model.imageSource} />
          <Spacer x={2} y={2} />
          <WaffleText
            style={{
              fontSize: 20,
              fontWeight: '500',
              textAlign: 'center',
            }}>
            {model.label}
          </WaffleText>
          <Spacer x={2} y={2} />
          <CoverageCardDescription>{model.description}</CoverageCardDescription>
          <Spacer x={2} y={4} />
        </Container>
      ))}
      <Container key={lastItem.label}>
        <CoverageTitleIcon source={lastItem.imageSource} />
        <Spacer x={2} y={2} />
        <WaffleText
          style={{
            fontSize: 20,
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {lastItem.label}
        </WaffleText>
        <Spacer x={2} y={2} />
        <CoverageCardDescription>
          {lastItem.description}
        </CoverageCardDescription>
      </Container>
    </QuoteScreenSectionContainer>
  );
}
