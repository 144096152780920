import { useMutation } from '@apollo/client';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';
import { Platform } from 'react-native';
import WaffleText from '../Components/WaffleText';
import InviteEntryText from '../Components/Referral/InviteEntryText';
import Popup from '../Components/Popup';
import { VerticalCenter } from '../Components/VerticalCenter';
import { CREATE_REFERRAL } from '../GraphQL/Waffle/Queries';
import { Spacer } from '../Components/SimpleComponents';
import * as yup from 'yup';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { ContentUnified } from '../Components/ContentShared';
import { GreenHeaderImage } from '../Components/HeaderNavTitle.web';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../screen-config';
import {
  CreateReferral,
  CreateReferralVariables,
} from '../../../operation-result-types';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';

const Text = styled(WaffleText)`
  width: 287px;
  color: #1e2368;
  font-size: 18px;
  letter-spacing: 0.22px;
  line-height: 23px;
`;

const TitleText = styled(WaffleText)`
  width: 287px;
  color: #1e2368;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.39px;
  line-height: 35px;
`;

const View = styled.View``;

const ImageCheck = styled.Image`
  height: 122px;
  width: 122px;
`;

const SentText = styled(WaffleText)`
  margin-top: 30px;
  height: 42.36px;
  width: 296px;
  font-size: 25px;
  text-align: center;
`;

const emailChecker = yup.string().required().email();

const ReferralView = ({ children }: { children: React.ReactNode }) => {
  if (Platform.OS !== 'web') {
    return (
      <KeyboardViewMaybe
        contentContainerStyle={[{ flex: 1 }]}
        extraScrollHeight={35}>
        {children}
      </KeyboardViewMaybe>
    );
  } else {
    return <VerticalCenter>{children}</VerticalCenter>;
  }
};

const ReferralScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Referral'>) => {
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [createReferral] = useMutation<CreateReferral, CreateReferralVariables>(
    CREATE_REFERRAL
  );
  const disabled = !emailChecker.isValidSync(email);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Refer Someone',
      headerShown: isNativeOrMobileWeb(),
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  if (popup) {
    setTimeout(() => {
      setPopup(false);
      setTimeout(async () => navigation.navigate(HomeScreenName), 500);
    }, 1500);
  }

  /*
              <Text>
              Refer your loved ones to us, and they'll each get a 10% reduction in
              their first year premium.
            </Text>

            <Text>
              For every referral, not only do you get the
              gratification of helping protect those closest to you, you will
              also see a reduction in your premium.
            </Text>

   */

  return (
    <ContentUnified title={'Refer Someone'} image={GreenHeaderImage}>
      <ReferralView>
        <VerticalCenter>
          <TitleText>Share Waffle with your family and friends!</TitleText>

          <Spacer y={2} />

          <Text>
            Refer your loved ones to us and help protect those closest to you.
          </Text>

          <Spacer y={2} />

          <InviteEntryText
            disabled={disabled}
            onPress={async () => {
              setPopup(true);
              await createReferral({ variables: { email } });
            }}
            onChangeText={(v: string) => setEmail(v)}
          />
        </VerticalCenter>

        <Popup key={'popup'} visible={popup} noClose={true}>
          <View style={{ height: 332 }}>
            <VerticalCenter>
              <ImageCheck
                source={require('../../assets/elements/step-check.png')}
              />
              <SentText>Your referral has been sent!</SentText>
            </VerticalCenter>
          </View>
        </Popup>
      </ReferralView>
    </ContentUnified>
  );
};

export default ReferralScreen;
