import { useQuery } from '@apollo/client';
import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import Products from '../../Constants/Products';
import { PlainView, Shadow, Spacer } from '../../Components/SimpleComponents';
import {
  HorizontalPacked,
  HorizontalSpread,
} from '../../Components/SimpleLayouts';
import {
  CoverageFilter,
  POLICY_STATUS_EXTERNAL_PENDING,
  POLICY_STATUS_PENDING,
  PolicyLongFrequencyLabel,
  PolicyNormalize,
  TotalPolicyPrice,
} from '../../Helper/PolicyHelper';
import { priceFmt, stringToJsonObject } from '../../Helper/MiscFunctions';
import { TemplatedMarkup } from '../../Components/Markup';
import {
  IsSmallScreen,
  AdaptiveButtonWidth,
  isFullWeb,
} from '../../Helper/DeviceHelper';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import {
  GetFinalizedUserPolicies_finalizedUserPolicies,
  GetFinalizedUserPolicies_finalizedUserPolicies_coverage,
  LocalUser,
} from '../../../../operation-result-types';
import { FinalizedPolicyProps } from '../../../component-config';
import { EditOrLink } from './EditOrLink';
import { CoverageDescription } from './CoverageDescription';
import { isPolicyActive } from './utils';
import { PolicyDescriptionActions } from './PolicyDescriptionActions';

/* This started as a copy of PolicyDetail for the View Your Plan screen.
 *  should get merged back in
 */
const ProductImage = styled.Image`
  width: 59px;
  height: 59px;
`;

const DetailText = styled(WaffleText)`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  /* */
  margin-top: 11px;
`;

const DetailViewContainer = styled.View`
  margin-left: 10px;
  margin-right: 9px;
  margin-bottom: 10px;
`;

const DetailView = styled.View`
  background-color: #fff;
  border-radius: 9px;
  width: 356px;
  overflow: hidden;
`;

const Price = styled(WaffleText)`
  height: 25px;
  line-height: 25px;
  /* */
  color: #070d59;
  font-size: 18.32px;
  font-weight: 700;
  letter-spacing: -0.6px;
  text-align: center;
`;

const PerMonth = styled(WaffleText)`
  /* */
  color: #070d59;
  font-size: 6.4px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 9px;
  height: 9px;
  text-align: center;
  text-transform: uppercase;
`;

const DetailBox = styled.View`
  padding: 18px 10px 24px 22px;
  width: 293px;
  height: 100%;
`;

const ValueBox = styled.View`
  width: 63px;
  background-color: #d1e9ff;
`;

type Policy = GetFinalizedUserPolicies_finalizedUserPolicies;
type Coverage = GetFinalizedUserPolicies_finalizedUserPolicies_coverage;

type PolicyTemplateProps = {
  template: any;
  policy: Policy;
};

type PolicyProps = {
  policy: Policy;
};

const PolicyTemplate = ({ template, policy }: PolicyTemplateProps) => {
  const { data: user } = useQuery<LocalUser>(GET_LOCAL_USER);
  const inputs = policy?.answerInputs; // todo : type!
  const add_ons = policy.addOns ?? [];

  if (template) {
    return (
      <TemplatedMarkup
        style={{
          paragraph: {
            marginTop: 9,
            marginBottom: 0,
          },
        }}
        template={template}
        context={{ user, inputs, add_ons }}
      />
    );
  }

  return <></>;
};

const PolicyDescription = ({ policy }: PolicyProps) => {
  const templates = stringToJsonObject(policy?.product?.templates);

  return (
    <PolicyTemplate policy={policy} template={templates?.manage_plan_header} />
  );
};

const PolicyDescriptionFooter = ({ policy }: PolicyProps) => {
  const templates = stringToJsonObject(policy?.product?.templates);

  return (
    <PolicyTemplate policy={policy} template={templates?.manage_plan_footer} />
  );
};

const EditLinkContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// export type PolicyDetailViewProps = {
//   policy: Policy;
// };

export const PolicyDetailView = ({ policy }: FinalizedPolicyProps) => {
  const { detailImg } = Products[policy.productId];
  const frequency = PolicyLongFrequencyLabel(policy);

  const coverageFilter = (c: Coverage) => CoverageFilter(policy, c);
  const style =
    Platform.OS === 'web'
      ? {
          height: '100%',
        }
      : {};
  const width = isFullWeb() ? 550 : AdaptiveButtonWidth();
  const detailWidth = IsSmallScreen() ? 237 : isFullWeb() ? 475 : 293;
  const detailNameWidth = IsSmallScreen() ? 150 : 175;
  const isActive = isPolicyActive(policy);

  // Logger(`policy: ${JSON.stringify(policy, null, 2)}`);

  return (
    <DetailViewContainer>
      <Shadow style={{ borderRadius: 9, width }}>
        <DetailView style={{ width, opacity: isActive ? 1.0 : 0.5 }}>
          <HorizontalSpread style={[{ alignItems: 'stretch' }, style]}>
            <DetailBox style={{ width: detailWidth }}>
              <HorizontalPacked>
                <ProductImage source={detailImg} resizeMode={'contain'} />
                <Spacer x={1} />
                <DetailText style={{ width: detailNameWidth }}>
                  {policy.product.name} Details
                </DetailText>
              </HorizontalPacked>

              <PolicyDescription policy={policy} />
              {policy.coverage.filter(coverageFilter).map((c, idx) => (
                <CoverageDescription key={idx} coverage={c} />
              ))}

              <PolicyDescriptionFooter policy={PolicyNormalize(policy)} />

              <PolicyDescriptionActions policy={policy} />
            </DetailBox>

            <ValueBox
              style={{
                paddingTop: 23,
                paddingBottom: 25,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              {policy.status !== POLICY_STATUS_EXTERNAL_PENDING &&
                policy.status !== POLICY_STATUS_PENDING && (
                  <PlainView>
                    <Price>${priceFmt(TotalPolicyPrice(policy))}</Price>
                    {!!frequency && <PerMonth>{frequency}</PerMonth>}
                  </PlainView>
                )}

              <EditLinkContainer>
                <EditOrLink policy={policy} />
              </EditLinkContainer>
            </ValueBox>
          </HorizontalSpread>
        </DetailView>
      </Shadow>
    </DetailViewContainer>
  );
};
