const root = '../../../../assets/images/products/pet';

const petImages = {
  accidents: require(`../../../../assets/images/products/pet/pet_accidents.webp`),
  alternative: require(`../../../../assets/images/products/pet/pet_alternate_therapies.webp`),
  behavior: require('../../../../assets/images/products/pet/pet_behavioral.webp'),
  bloodTests: require(`../../../../assets/images/products/pet/pet_blood_tests.webp`),
  breedingCosts: require(`../../../../assets/images/products/pet/breeding_costs.webp`),
  cosmetic: require(`../../../../assets/images/products/pet/pet_cosmetic.webp`),
  deWorming: require(`../../../../assets/images/products/pet/pet_deworming.webp`),
  dental: require(`../../../../assets/images/products/pet/pet_dental_costs.webp`),
  endOfLife: require(`../../../../assets/images/products/pet/end_of_life.webp`),
  experimental: require(`../../../../assets/images/products/pet/pet_experimental.webp`),
  healthCertificate: require(`../../../../assets/images/products/pet/pet_health_certificate.webp`),
  hereditary: require(`../../../../assets/images/products/pet/pet_hereditary.webp`),
  hospital: require(`../../../../assets/images/products/pet/hospital.webp`),
  illness: require(`../../../../assets/images/products/pet/pet_illness.webp`),
  illnessPetChart: require(`../../../../assets/images/products/pet/illness_pet_chart.webp`),
  medication: require(`../../../../assets/images/products/pet/medication.webp`),
  poopTest: require(`../../../../assets/images/products/pet/pet_poop_test.webp`),
  poisonControl: require(`../../../../assets/images/products/pet/poinson_control.webp`),
  poweredBy: require(`../../../../assets/images/products/pet/powered_by_aspca.webp`),
  preExisting: require(`../../../../assets/images/products/pet/pet_preexisting.webp`),
  product: require(`../../../../assets/images/products/pet/pet_product.webp`),
  urine: require(`../../../../assets/images/products/pet/pet_urine_test.webp`),
  vaccines: require(`../../../../assets/images/products/pet/pet_vaccines.webp`),
  wellness: require(`../../../../assets/images/products/pet/pet_wellness.webp`),
  wellness_prevention: require(`../../../../assets/images/products/pet/wellness_prevention.webp`),
  xray: require(`../../../../assets/images/products/pet/x_ray.webp`),
};

export default petImages;
