import React from 'react';
import styled from 'styled-components/native';
import WaffleText, { WaffleHeaderText } from '../../Components/WaffleText';
import { Spacer } from '../../Components/SimpleComponents';
import ContentLoader from 'react-content-loader';

const ProductLabelContainer = styled.View`
  padding-left: 22px;
  padding-right: 22px;
  background: #fff6ea;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export type SafeSecureCheckoutProps = {
  isLoading?: boolean;
  paymentFrequency: string;
  price: string;
  selectedProductId: string;
};

export function SafeSecureCheckout({
  paymentFrequency,
  price,
  isLoading,
  selectedProductId,
}: SafeSecureCheckoutProps): React.ReactElement {
  return (
    <>
      <WaffleHeaderText>Last Step!</WaffleHeaderText>
      <Spacer y={1.5} />
      <WaffleText style={{ fontSize: 20, textAlign: 'center' }}>
        Enter your payment information below
      </WaffleText>
      <Spacer y={6} />
      <ProductLabelContainer>
        <WaffleText
          style={{
            fontSize: 20,
            textAlign: 'center',
            fontWeight: 'normal',
          }}>{`${selectedProductId || ''} Insurance: `}</WaffleText>
      </ProductLabelContainer>
      {isLoading === true ? (
        <ContentLoader height={65} width={320}>
          <rect x={0} y={16} width={320} height={64} />
        </ContentLoader>
      ) : (
        <WaffleText
          style={{
            fontSize: 50,
            textAlign: 'center',
          }}>{`$${price} ${paymentFrequency}`}</WaffleText>
      )}
    </>
  );
}
