import WaffleText from '../../Components/WaffleText';
import React from 'react';

type Prop = {
  children: string;
};

export default function QuoteScreenSectionTitle({ children }: Prop) {
  return (
    <WaffleText
      style={{
        maxWidth: 334,
        textAlign: 'center',
        fontSize: 28,
        fontWeight: '700',
      }}>
      {children}
    </WaffleText>
  );
}
