import petImages from '../utils/pet-images';
import { WhatIsNotCoveredModel } from '../../QuoteScreen/models/what-is-not-covered.model';

const whatIsNotCoveredPetModel: WhatIsNotCoveredModel[] = [
  {
    label: 'Pre-Existing Conditions',
    description:
      'Pre-existing conditions are not covered. They are defined as occurring before coverage begins or during a waiting period. Learn more here.',
    imageSource: petImages.preExisting,
  },
  {
    label: 'Cosmetic Procedures',
    description:
      'The plans don’t cover elective procedures, such as tail docking, ear cropping, cosmetic orthodontic procedures, and claw removal.',
    imageSource: petImages.cosmetic,
  },
  {
    label: 'Breeding Costs',
    description:
      'The plans don’t cover elective procedures, such as tail docking, ear cropping, cosmetic orthodontic procedures, and claw removal.',
    imageSource: petImages.breedingCosts,
  },
  {
    label: 'Dental Cleanings',
    description:
      'Unless they are required for the treatment of a covered illness.',
    imageSource: petImages.dental,
  },
  {
    label: 'Experimental or Investigative Treatment',
    description:
      'Therapies that haven’t been proven or widely accepted by the veterinarian community to be effective.',
    imageSource: petImages.experimental,
  },
];

export default whatIsNotCoveredPetModel;
