// do we want to filter CFAR?
import { useQuery } from '@apollo/client';
import {
  GetFinalizedUserPolicies_finalizedUserPolicies_coverage,
  LocalUser,
} from '../../../../operation-result-types';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import { valToLabel } from '../../Helper/MiscFunctions';
import { TemplatedMarkup } from '../../Components/Markup';
import React from 'react';

type Coverage = GetFinalizedUserPolicies_finalizedUserPolicies_coverage;

type CoverageProps = {
  coverage: Coverage;
};

export const CoverageDescription = ({ coverage: c }: CoverageProps) => {
  // This does the simplest variable substitution, but it should be pretty safe.
  // If we need something more complicated, we should move to a proper sandboxed
  // variable substitution mechanism.
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const value = valToLabel(c.coverageLimit, c.coverage.choices);

  if (value === 'false') {
    // we get that with CFAR
    return <></>;
  }

  // Legacy template substitution (deprecate!)
  const description =
    c?.coverage?.policyDescription?.replace(
      '{value}',
      value?.toLocaleString()
    ) ?? '';

  // New template handling
  return (
    <TemplatedMarkup
      style={{
        paragraph: {
          marginTop: 9,
          marginBottom: 0,
        },
      }}
      template={description || c.coverage.name}
      context={{ user, value: value?.toLocaleString() }}
    />
  );
};
