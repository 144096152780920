import type { IncrementorControlValue } from './models/Controls';
import CoverageCard from '../../Components/CoverageCard';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardCounter from '../../Components/CoverageCard/CoverageCardCounter';
import React from 'react';

type CustomizePolicyProps = {
  deductible: number;
  deductibleControl: IncrementorControlValue;
  copay: number;
  copayControl: IncrementorControlValue;
  annualLimit: number;
  annualLimitControl: IncrementorControlValue;
};

export default function CustomizePolicy({
  deductible,
  deductibleControl,
  copay,
  copayControl,
  annualLimit,
  annualLimitControl,
}: CustomizePolicyProps): React.ReactElement {
  return (
    <CoverageCard bgColor="white">
      <CoverageCardTitle>Customize Your Policy</CoverageCardTitle>
      <Spacer x={2} y={5} />
      <CoverageCardCounter
        label="Deductible (For each pet)"
        value={deductible}
        onIncrease={deductibleControl.onIncreaseAsync}
        onDecrease={deductibleControl.onDecreaseAsync}
        description="The annual amount of covered expenses that must be satisfied before reimbursement starts"
      />

      <Spacer x={2} y={5} />

      <CoverageCardCounter
        label="Copay % (For each pet)"
        value={copay}
        isMonetary={false}
        onIncrease={copayControl.onIncreaseAsync}
        onDecrease={copayControl.onDecreaseAsync}
        description="The % of covered costs that are not reimbursable"
      />

      <Spacer x={2} y={5} />
      <CoverageCardCounter
        label="Annual Limit (For each pet)"
        value={annualLimit}
        onIncrease={annualLimitControl.onIncreaseAsync}
        onDecrease={annualLimitControl.onDecreaseAsync}
        description="Maximum amount reimbursed over a 12-month period"
      />
    </CoverageCard>
  );
}
