import CoverageCard from '../../Components/CoverageCard';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import petImages from './utils/pet-images';
import React from 'react';
import styled from 'styled-components/native';
import { ImageSourcePropType, TouchableWithoutFeedback } from 'react-native';
import WaffleText from '../../Components/WaffleText';
import {
  ItemLabel,
  ItemLabelOrangeLink,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';

const Container = styled.View`
  flex-direction: column;
  width: 100%;
`;
const Row = styled.View`
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const LabeledIconContainer = styled.View`
  width: 110px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const PropertyIcon = styled.Image`
  width: 68px;
  height: 68px;
`;

type LabeledIconProps = {
  source: ImageSourcePropType;
  label: string;
};
const LabeledIcon = function LabeledIcon({ source, label }: LabeledIconProps) {
  return (
    <LabeledIconContainer>
      <PropertyIcon source={source} />
      <Spacer x={1} y={1} />
      <WaffleText style={{ fontSize: 10, textAlign: 'center' }}>
        {label}
      </WaffleText>
    </LabeledIconContainer>
  );
};

const accidentModel = [
  {
    label:
      'Alternative Therapy\nAcupuncture\nChiropractic Care\nHydrotherapy\nand more!',
    source: petImages.alternative,
  },
  {
    label:
      'Prescription Medication\nPrescription Food\nSupplements\n*for treated conditions',
    source: petImages.medication,
  },
  {
    label: 'Hospitalization\nSurgery\nTooth Extractions\nStem Cell Therapy',
    source: petImages.hospital,
  },
  {
    label: 'End of Life Expenses\nEuthanasia\nBurialCremation',
    source: petImages.endOfLife,
  },
  {
    label: 'X-Rays\nCT Scans\nMRIs',
    source: petImages.xray,
  },
  {
    label: 'Poison Control\nConsultation\nFees',
    source: petImages.poisonControl,
  },
];

type AccidentsProp = {
  onSamplePolicyOpen: () => Promise<void>;
};

export default function Accidents({
  onSamplePolicyOpen,
}: AccidentsProp): React.ReactElement {
  const content =
    'Injuries and emergencies like torn ligaments, bite wounds, cuts, broken bones, swallowed objects, and toxic ingestion.';
  return (
    <CoverageCard bgColor="white">
      <CoverageTitleIcon source={petImages.accidents} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Accidents</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>{content}</CoverageCardDescription>
      <Spacer x={2} y={4} />
      <CoverageCardTitle>Benefits Your Pet Will Love</CoverageCardTitle>
      <Spacer x={2} y={4} />

      <Container>
        <Row>
          {accidentModel.slice(0, 3).map((accident) => (
            <LabeledIcon
              key={accident.label}
              source={accident.source}
              label={accident.label}
            />
          ))}
        </Row>
        <Spacer x={2} y={4} />
        <Row>
          {accidentModel.slice(3, 6).map((accident) => (
            <LabeledIcon
              key={accident.label}
              source={accident.source}
              label={accident.label}
            />
          ))}
        </Row>
      </Container>
      <Spacer y={3} x={2} />
      <WaffleText
        style={{
          fontSize: 13,
          fontStyle: 'normal',
          fontWeight: 'normal',
          textAlign: 'center',
          color: '#88847E',
        }}>
        <TouchableWithoutFeedback onPress={onSamplePolicyOpen}>
          <ItemLabel
            style={{
              fontSize: 13,
              fontWeight: '400',
              textAlign: 'center',
              color: '#88847E',
            }}>
            Above is only a summary, please see the sample policy for more
            details{' '}
            <ItemLabelOrangeLink style={{ fontSize: 11, fontWeight: '400' }}>
              here.
            </ItemLabelOrangeLink>
          </ItemLabel>
        </TouchableWithoutFeedback>
      </WaffleText>
    </CoverageCard>
  );
}
