import { useQuery } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import { Spacer } from '../../Components/SimpleComponents';
import { PolicyDetailView } from './PolicyDetailView';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { WaffleLightBlue } from '../../Constants/Style';
import {
  ContentUnified,
  FooterSpacer,
  ScrollingBlock,
} from '../../Components/ContentShared';
import { BlueHeaderImage } from '../../Components/HeaderNavTitle.web';
import { LoadingUnified } from '../../Components/LoadingUnified';
import {
  AdaptiveButtonWidth,
  isNativeOrMobileWeb,
} from '../../Helper/DeviceHelper';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ProductChooserScreenName,
  RootStackParamList,
} from '../../../screen-config';
import { USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { FinalizedPoliciesProps } from '../../../component-config';
import { GetFinalizedUserPolicies } from '../../../../operation-result-types';
import { BuildVersionAndPlatform } from '../../Helper/UserHelpers';

const title = 'Manage Your Plan';

const PolicyDetailList = ({ policies }: FinalizedPoliciesProps) => (
  <>
    {policies.map((policy) => (
      <PolicyDetailView key={policy.id} policy={policy} />
    ))}
  </>
);

const ManagePlanScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Manage Plan'>) => {
  const { data, loading, error } = useQuery<GetFinalizedUserPolicies>(
    USER_POLICIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: BuildVersionAndPlatform() },
    }
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerShown: isNativeOrMobileWeb(),
      headerBackTitleVisible: false,
    });
  }, [navigation]);

  if (loading || error) {
    return (
      <LoadingUnified
        title={title}
        errors={error?.message}
        image={BlueHeaderImage}
      />
    );
  }

  const policies = data?.finalizedUserPolicies ?? [];

  return (
    <ContentUnified
      title={title}
      image={BlueHeaderImage}
      ChildrenWrapper={ScrollingBlock}>
      <Spacer y={3} />

      <PolicyDetailList policies={policies} />

      <Spacer y={2} />

      <WaffleButtonAsync
        name={'Add Product'}
        style={{
          width: AdaptiveButtonWidth(),
          backgroundColor: WaffleLightBlue,
          borderRadius: 9,
          marginLeft: 10,
          marginRight: 9,
        }}
        disabled={false}
        onPress={async () =>
          await navigation.navigate(ProductChooserScreenName)
        }
      />

      <Spacer y={2} />

      <FooterSpacer />
    </ContentUnified>
  );
};

export default ManagePlanScreen;
