import {
  IsIncompleteUser,
  RefreshUserState,
  UpdateUserStateOnServer,
} from '../../../Helper/UserHelpers';
import { USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY } from '../../../Helper/NavigationService';
import {
  RootStackParamList,
  UpdateGuestUserScreenName,
} from '../../../../screen-config';
import { SuggestedCoverageNextNavigation } from '../../../Components/DesignPolicy/SuggestedCoverageShared';
import { LocalUser_user } from '../../../../../operation-result-types';
import { StackNavigationProp } from '@react-navigation/stack';
import { ApolloClient } from '@apollo/client';

interface CheckoutPolicyProps {
  isGuest: boolean;
  user: LocalUser_user;
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  navigation: StackNavigationProp<RootStackParamList>;
  policyProductIds: string[];
}

export default async function goToNextPolicyOrCheckoutAsync({
  isGuest,
  user,
  client,
  navigation,
  policyProductIds,
}: CheckoutPolicyProps) {
  // If user is guest, then make them fill out more information
  if (IsIncompleteUser(isGuest, user)) {
    await UpdateUserStateOnServer(
      client,
      USER_STATE_ACCOUNT_DETAILS_BEFORE_PAY
    );

    await RefreshUserState(client);

    await navigation.navigate(UpdateGuestUserScreenName);

    return;
  }

  await SuggestedCoverageNextNavigation(
    navigation,
    client,
    isGuest,
    policyProductIds
  );
}
