import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import React from 'react';

export const ViewPolicyDocumentButton = ({
  name,
  onPress,
}: {
  name: string;
  onPress: () => Promise<void>;
}) => (
  <WaffleButtonAsync
    name={name}
    style={{
      marginTop: 10,
      width: '90%',
      borderRadius: 7,
      backgroundColor: '#FFFFFF',
      borderWidth: 1,
      borderColor: '#AAAAAA',
    }}
    pressedName={'Fetching policy...'}
    textStyle={{
      color: '#000000',
    }}
    onPress={onPress}
  />
);
