import CoverageCard from '../../Components/CoverageCard';
import CoverageTitleIcon from '../../Components/CoverageCard/CoverageCardTitleIcon';
import petImages from './utils/pet-images';
import { Spacer } from '../../Components/SimpleComponents';
import CoverageCardTitle from '../../Components/CoverageCard/CoverageCardTitle';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import Toggle from '../../Components/Toggle';
import { ToggleControlValue } from './models/Controls';
import React from 'react';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';

type Props = {
  hasIllness: boolean;
  hasIllnessControl: ToggleControlValue;
};

const MiniCoverageCard = styled.View`
  padding: 11px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100px;
  min-height: 164px;
  background-color: #f1f1f1;
  border-radius: 16px;
`;

const MiniCoverageCardsContainer = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  min-height: 164px;
  flex: 1;
`;

const MiniCoverageCardTitleText = styled(WaffleText)`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
`;

const MiniCoverageCardContentText = styled(WaffleText)`
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
`;

const Icon = styled.Image`
  width: 23px;
  height: 22px;
`;

const coverages = [
  {
    title: 'Major & Minor\nIllnesses',
    content:
      'Cancer\nArthritis\nHypothyroidism\nEar Infections\nDigestive Problems',
  },
  {
    title: 'Hereditary &\nCongenital Conditions',
    content: 'Heart Disease\nIVDD\nEye Disorders\nHip Dysplasia',
  },
  {
    title: 'Behavioral\nIssues',
    content: 'Excessive Licking\nFur Pulling\nPacing\nDestructive Chewing',
  },
];

export default function Illness({
  hasIllness,
  hasIllnessControl,
}: Props): React.ReactElement {
  const content =
    'Covers the costs of major & minor illnesses, such as cancer, arthritis, hypothyroidism, ear infections, digestive problems, and urinary tract infections. It can cover expenses for things from diagnosing the illness to the treatments your pet needs to get better.';

  return (
    <CoverageCard bgColor="white">
      <CoverageTitleIcon source={petImages.illness} />
      <Spacer x={2} y={2} />
      <CoverageCardTitle>Illness</CoverageCardTitle>
      <Spacer x={2} y={2} />
      <CoverageCardDescription>{content}</CoverageCardDescription>
      <Spacer x={2} y={4} />
      <Toggle
        isEnabled={hasIllness}
        onPress={hasIllnessControl.onToggleAsync}
      />
      <Spacer x={2} y={4} />
      <CoverageCardTitle>Covers Cost For...</CoverageCardTitle>
      <Spacer x={2} y={4} />
      <MiniCoverageCardsContainer>
        {coverages.map((coverage) => (
          <MiniCoverageCard key={coverage.title}>
            <Icon source={petImages.illnessPetChart} key={coverage.title} />
            <Spacer x={2} y={2} />
            <MiniCoverageCardTitleText>
              {coverage.title}
            </MiniCoverageCardTitleText>
            <Spacer x={2} y={2} />
            <MiniCoverageCardContentText>
              {coverage.content}
            </MiniCoverageCardContentText>
          </MiniCoverageCard>
        ))}
      </MiniCoverageCardsContainer>
    </CoverageCard>
  );
}
