import PriceButton from '../../Components/PriceButton';
import React from 'react';
import { Animated } from 'react-native';
import { POLICY_TYPE_MONTHLY } from '../../Helper/PolicyHelper';

type Props = {
  onPress: () => Promise<void>;
  isUpdating: boolean;
  currentContainerViewScrollY: Animated.Value;
  startingY: number;
  windowHeight: number;
  price: number;
};

export function QuoteScreenPriceButton(props: Props): JSX.Element {
  return (
    <PriceButton
      size="small"
      animatedTextStyle={{ fontSize: 24 }}
      isUpdating={props.isUpdating}
      onPress={props.onPress}
      policyFrequency={POLICY_TYPE_MONTHLY}
      positionStyle={{
        position: 'absolute',
        transform: [
          {
            translateY: props.currentContainerViewScrollY.interpolate({
              inputRange: [0, 250, 5000],
              outputRange: [
                props.startingY, // Start with position of View container.
                250 + (props.windowHeight - 150 - 44), // Move to 150 above the bottom of screen and stay there
                5000 + (props.windowHeight - 150 - 44),
              ],
              extrapolate: 'clamp',
            }),
          },
        ],
      }}
      prefixPrice="Pay "
      price={props.price}
    />
  );
}
