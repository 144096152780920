import React from 'react';
import { View, StyleSheet } from 'react-native';
import QuoteScreenSectionTitle from './QuoteScreenSectionTitle';
import { Spacer } from '../../Components/SimpleComponents';
import { GreySection, WhiteSection } from '../CyberQuoteScreen/Shared';
import styled from 'styled-components/native';
import CoverageCardDescription from '../../Components/CoverageCard/CoverageCardDescription';
import WaffleText from '../../Components/WaffleText';

const styles = StyleSheet.create({
  sectionContentContainer: {},
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});

type Props = {
  children: React.ReactNode;
  backgroundColor: 'gray' | 'white';
  title: string;
  icon?: React.ReactNode;
  description?: string;
};

const IconContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const TitleContainer = styled.View`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const QuoteScreenTitleDescriptionText = styled(WaffleText)`
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  max-width: 310px;
  color: #88847e;
`;

export default function QuoteScreenSectionContainer({
  children,
  backgroundColor,
  description,
  icon,
  title,
}: Props) {
  const Container = backgroundColor === 'gray' ? GreySection : WhiteSection;

  return (
    <Container>
      <TitleContainer>
        {icon ? (
          <>
            <IconContainer>{icon}</IconContainer>
            <Spacer y={2.5} />
          </>
        ) : null}
        <QuoteScreenSectionTitle>{title}</QuoteScreenSectionTitle>
        <Spacer y={2.5} />
        {description ? (
          <QuoteScreenTitleDescriptionText>
            {description}
          </QuoteScreenTitleDescriptionText>
        ) : null}
      </TitleContainer>
      <View style={styles.sectionContentContainer}>
        <Spacer y={2.5} />
        <View style={styles.sectionContent}>{children}</View>
      </View>
    </Container>
  );
}
