import { useQuery } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../../screen-config';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import {
  GetFinalizedUserPolicies,
  GetProducts,
  GetProductsVariables,
} from '../../../../operation-result-types';
import { GET_PRODUCTS, USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { ContentUnified, ScrollingBlock } from '../../Components/ContentShared';
import { OrangeHeaderImage } from '../../Components/HeaderNavTitle.web';
import { VerticalCenter } from '../../Components/SimpleLayouts';
import { LoadingUnified } from '../../Components/LoadingUnified';
import { Promo } from './Promo';
import { Platform } from 'react-native';
import { BuildVersionAndPlatform } from '../../Helper/UserHelpers';

const PurchaseCompleteScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Step Complete'>) => {
  const { data, loading, refetch } = useQuery<GetFinalizedUserPolicies>(
    USER_POLICIES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { loading: productsLoading, data: productsData } = useQuery<
    GetProducts,
    GetProductsVariables
  >(GET_PRODUCTS, {
    variables: BuildVersionAndPlatform(),
  });
  const policies = data?.finalizedUserPolicies;

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: true,
    });
  }, [navigation]);

  useFocusEffect(() => {
    if (!policies?.length) {
      refetch().then();
    }
  });

  const onPress = async () => {
    navigation.dispatch((state) => {
      // Remove the home route from the stack
      const routes = state.routes.filter((r) => r.name === HomeScreenName);

      if (routes.length === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Ignoring the key to reset nav state
        routes.push({ name: HomeScreenName });
      }

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      });
    });
  };

  if (loading || productsLoading) {
    return <LoadingUnified title={'Congratulations!'} addingProduct={true} />;
  }

  return (
    <ContentUnified
      image={OrangeHeaderImage}
      ChildrenWrapper={ScrollingBlock}
      addingProduct={true}>
      <VerticalCenter
        style={{
          width: Platform.OS === 'ios' ? '100%' : undefined,
        }}>
        <Promo
          policies={policies}
          products={productsData?.products}
          onPress={onPress}
        />
      </VerticalCenter>
    </ContentUnified>
  );
};

export default PurchaseCompleteScreen;
