import AsyncState from '../../QuoteScreen/utils/AsyncState';
import updateCoverageValueAndSetStateWithMapperAsync from '../../QuoteScreen/utils/update-coverage-and-map-to-state';
import {
  GetDraftUserPolicies_draftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies_coverage,
} from '../../../../../operation-result-types';
import { decrementValue, incrementValue } from '../../../Helper/ValueFunctions';
import { PolicyFinder } from '../../QuoteScreen/utils/PolicyFinder';
import { DraftPolicyToStateMapper } from '../../QuoteScreen/utils/DraftPolicyToStateMapper';
import React from 'react';
import { ApolloClient } from '@apollo/client';

interface ValueUpdaterProps<LocalState extends AsyncState> {
  coverage: GetDraftUserPolicies_draftUserPolicies_coverage;
  setState: React.Dispatch<React.SetStateAction<LocalState>>;
  mapDraftPolicyToState: DraftPolicyToStateMapper<LocalState>;
  client: ApolloClient<object>;
  findPolicy: PolicyFinder;
}

interface ChoiceUpdaterProps<
  LocalState extends AsyncState,
  Choices extends string
> extends ValueUpdaterProps<LocalState> {
  newValue: Choices;
}

export function updateChoiceValueAsync<
  LocalState extends AsyncState,
  Choices extends string
>({
  coverage,
  setState,
  newValue,
  client,
  mapDraftPolicyToState,
  findPolicy,
}: ChoiceUpdaterProps<LocalState, Choices>): Promise<void> {
  return updateCoverageValueAndSetStateWithMapperAsync<LocalState>({
    client,
    coverageId: coverage.id,
    coverageLimit: newValue as string,
    setState,
    mapDraftPolicyToState,
    findPolicy: findPolicy,
  });
}

interface NumericValueUpdaterProps<LocalState extends AsyncState>
  extends ValueUpdaterProps<LocalState> {
  currentValue: number;
}

export function incrementValuesAsync<LocalState extends AsyncState>({
  client,
  coverage,
  setState,
  mapDraftPolicyToState,
  currentValue,
  findPolicy,
}: NumericValueUpdaterProps<LocalState>): Promise<void> {
  const newValue = incrementValue(currentValue.toString(), coverage.coverage);

  return updateCoverageValueAndSetStateWithMapperAsync<LocalState>({
    client,
    coverageId: coverage.id,
    coverageLimit: newValue,
    setState,
    mapDraftPolicyToState,
    findPolicy,
  });
}

export function decrementValueAsync<LocalState extends AsyncState>({
  client,
  coverage,
  setState,
  mapDraftPolicyToState,
  currentValue,
  findPolicy,
}: NumericValueUpdaterProps<LocalState>): Promise<void> {
  const newValue = decrementValue(currentValue.toString(), coverage.coverage);

  return updateCoverageValueAndSetStateWithMapperAsync<LocalState>({
    client,
    coverageId: coverage.id,
    coverageLimit: newValue,
    setState,
    mapDraftPolicyToState,
    findPolicy,
  });
}

interface ToggleUpdaterProps<
  LocalState extends AsyncState,
  ToggleValue extends string
> extends ValueUpdaterProps<LocalState> {
  currentValue: ToggleValue;
}

export function toggleValueAsync<
  LocalState extends AsyncState,
  ToggleValue extends string
>({
  coverage,
  currentValue,
  setState,
  client,
  findPolicy,
  mapDraftPolicyToState,
}: ToggleUpdaterProps<LocalState, ToggleValue>): Promise<void> {
  const choices = coverage.coverage.choices;

  const choicesOnlyWithOtherValue = choices
    .split(',')
    .map((c) => c.split(':')[0])
    .filter((c) => c !== currentValue);

  return updateCoverageValueAndSetStateWithMapperAsync<LocalState>({
    client,
    coverageId: coverage.id,
    coverageLimit: choicesOnlyWithOtherValue[0],
    setState,
    mapDraftPolicyToState,
    findPolicy,
  });
}

export function setAppIsLoading<LocalState extends AsyncState>(
  draftRentersPolicy: GetDraftUserPolicies_draftUserPolicies,
  mapDraftPolicyToState: DraftPolicyToStateMapper<LocalState>,
  isLoading: boolean
) {
  return function setComposedState(prevState: LocalState): LocalState {
    const mappedState = mapDraftPolicyToState(draftRentersPolicy, prevState);
    return {
      ...mappedState,
      isLoading,
    };
  };
}
