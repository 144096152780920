import AsyncState from '../../QuoteScreen/utils/AsyncState';
import {
  GetDraftUserPolicies_draftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies_coverage,
} from '../../../../../operation-result-types';
import {
  ANNUAL_LIMIT,
  DEDUCTIBLE,
  COPAY,
  LEVEL,
  WELLNESS,
  WellnessValues,
  IllnessValues,
} from '../utils/pet.constants';
import type { CoverageId } from '../utils/pet.constants';
import { PolicyFinder } from '../../QuoteScreen/utils/PolicyFinder';
import { ProductPet } from '../../../Constants/Products';
import { DraftPolicyToStateMapper } from '../../QuoteScreen/utils/DraftPolicyToStateMapper';

export interface PetQuoteScreenLocalState extends AsyncState {
  annualLimit: number;
  copay: number;
  deductible: number;
  hasIllness: boolean;
  hasWellnessBasic: boolean;
  hasWellnessPrime: boolean;
  isLoading: boolean;
  quotePrice: number;
}

export const petQuoteScreenInitialState: PetQuoteScreenLocalState = {
  annualLimit: 0,
  copay: 0,
  deductible: 0,
  hasIllness: false,
  hasWellnessBasic: false,
  hasWellnessPrime: false,
  isLoading: true,
  quotePrice: 0,
};

const getCoverage = (
  coverages: GetDraftUserPolicies_draftUserPolicies_coverage[] = [],
  coverageId: CoverageId
) => {
  return coverages.find((c) => c.productCoverageId === coverageId);
};

export const findPetPolicy: PolicyFinder = (
  draftUserPolicies: GetDraftUserPolicies_draftUserPolicies[] = []
) => draftUserPolicies.find((policy) => policy.product?.id === ProductPet);

export const mapDraftPolicyToPetScreenLocalState: DraftPolicyToStateMapper<
  PetQuoteScreenLocalState
> = (
  draftPolicy: GetDraftUserPolicies_draftUserPolicies,
  prevState: PetQuoteScreenLocalState = petQuoteScreenInitialState
): PetQuoteScreenLocalState => {
  if (!draftPolicy?.coverage) return prevState;

  const {
    copayCoverage,
    deductibleCoverage,
    annualLimitCoverage,
    hasIllnessCoverage,
    wellnessCoverage,
  } = getPetCoverages(draftPolicy.coverage);

  const annualLimit = annualLimitCoverage.coverageLimit;
  const copay = copayCoverage.coverageLimit;
  const deductible = deductibleCoverage.coverageLimit;
  const hasIllness = hasIllnessCoverage.coverageLimit === IllnessValues.level2;
  const hasWellnessBasic =
    wellnessCoverage.coverageLimit === WellnessValues.basic;
  const hasWellnessPrime =
    wellnessCoverage.coverageLimit === WellnessValues.prime;
  const quotePrice = draftPolicy.price;

  return {
    ...prevState,
    annualLimit: parseInt(annualLimit),
    copay: parseInt(copay),
    deductible: parseInt(deductible),
    hasIllness,
    hasWellnessBasic,
    hasWellnessPrime,
    quotePrice,
  };
};

export const getPetCoverages = (
  coverages: GetDraftUserPolicies_draftUserPolicies_coverage[]
) => {
  const annualLimitCoverage = getCoverage(coverages, ANNUAL_LIMIT);
  const copayCoverage = getCoverage(coverages, COPAY);
  const deductibleCoverage = getCoverage(coverages, DEDUCTIBLE);
  const hasIllnessCoverage = getCoverage(coverages, LEVEL);
  const wellnessCoverage = getCoverage(coverages, WELLNESS);

  return {
    annualLimitCoverage,
    copayCoverage,
    deductibleCoverage,
    hasIllnessCoverage,
    wellnessCoverage,
  };
};
