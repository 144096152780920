import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ErrorText } from '../../Components/ErrorText';
import { Spacer } from '../../Components/SimpleComponents';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  DRAFT_POLICIES,
  FINALIZE_DRAFT_USER_POLICIES,
  GET_CUSTOMER_PAYMENT_METHODS,
  GET_LOCAL_USER,
} from '../../GraphQL/Waffle/Queries';
import {
  PolicyUniqueFrequency,
  TotalPolicyPrices,
} from '../../Helper/PolicyHelper';
import { saveCardToStripe } from '../../Helper/PaymentHelper.web';
import { ContentUnified, ScrollingBlock } from '../../Components/ContentShared';
import { Platform } from 'react-native';
import {
  FinalizeUserPolicies2,
  FinalizeUserPolicies2Variables,
  GetDraftUserPolicies,
  ListCustomerPaymentMethods,
  LocalUser,
} from '../../../../operation-result-types';
import { PaymentMethodType } from '../../Helper/PaymentHelper.generic';
import { Logger } from '../../Helper/Logger';
import { StackScreenProps } from '@react-navigation/stack';
import {
  RootStackParamList,
  CreateLoginScreenName,
  HomeScreenName,
} from '../../../screen-config';
import { isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import { UpdateUserStateOnServer } from '../../Helper/UserHelpers';
import { USER_STATE_COMPLETE } from '../../Helper/NavigationService';
import { useAuthContext } from '../AuthProvider';
import CardSubmissionForm from './CardSubmissionForm';
import { SafeSecureCheckout } from './SafeSecureCheckout';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import PaymentGuarantee from './PaymentGuarantee';
import { useWaffleLogoNavigationHeader } from '../../useWaffleLogoNavigationHeader';
import Sentry from '../../Sentry';

const title = 'Payment Info';

const PaymentInfoScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Payment Info'>) => {
  const stripe = useStripe();
  const elements = useElements();
  const client = useApolloClient();
  const { data: draftPolicies, loading: loadingDraftPolicies } =
    useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
      fetchPolicy: 'network-only',
    });
  const [finalizeUserPolicies] = useMutation<
    FinalizeUserPolicies2,
    FinalizeUserPolicies2Variables
  >(FINALIZE_DRAFT_USER_POLICIES);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const { data: paymentMethods } = useQuery<ListCustomerPaymentMethods>(
    GET_CUSTOMER_PAYMENT_METHODS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>({});
  const [error, setError] = useState(undefined);
  const policies = draftPolicies?.draftUserPolicies ?? [];
  const paymentFrequency = PolicyUniqueFrequency(policies);
  const { isGuest } = useAuthContext();
  const price = TotalPolicyPrices(policies);
  const selectedProductId = policies.map((p) => p.productId)[0];
  const userTracking = useUserTracking();

  useWaffleLogoNavigationHeader(navigation, 'Waffle', isNativeOrMobileWeb());

  useEffect(() => {
    const card = paymentMethods?.listCustomerPaymentMethods?.[0];

    if (!loadingDraftPolicies && !!card) {
      setPaymentMethod(card);
    }
  }, [paymentMethods, loadingDraftPolicies]);

  const onCardSave = async (zipCode: string) => {
    try {
      const { code, message, payment_method } = await saveCardToStripe(
        client,
        elements,
        stripe,
        user,
        zipCode
      );

      if (code) {
        Logger(
          `onCardSave error: code=${JSON.stringify(
            code
          )} message=${JSON.stringify(message)}`
        );

        throw new Error(message);
      }

      await setPaymentMethod(payment_method);
    } catch (err) {
      Sentry.captureException(err);
      Logger(
        `PaymentInfo onCardSave: confirmSetupIntent ERROR: ${JSON.stringify(
          err
        )}`
      );

      const message = err?.message ?? 'Error saving credit card';
      await setError(message);
      throw err;
    }
  };

  const onNext = async () => {
    setError(undefined);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    Logger(`PaymentInfoScreen onNext=${JSON.stringify(paymentMethod?.id)}`);

    const paymentMethodId = paymentMethod.id;

    try {
      await finalizeUserPolicies({ variables: { paymentMethodId } });
      userTracking?.publishOrderCompleted(draftPolicies?.draftUserPolicies);
    } catch (err) {
      Sentry.captureException(err);
      const message =
        err?.graphQLErrors?.[0]?.message ??
        err?.message ??
        'Error saving credit card';

      Logger(`error finalizing policy: ${message}`);

      await setError(message);

      return;
    }

    await UpdateUserStateOnServer(client, USER_STATE_COMPLETE);

    if (isGuest) {
      // go to create account
      await navigation.navigate(CreateLoginScreenName, {
        initialEmail: user.email,
        products: policies.map((p) => p.productId),
      });
    } else {
      // else if not guest, go to promo screen if eligible?  or just go home?
      await navigation.navigate(HomeScreenName);
    }
  };

  return (
    <ContentUnified
      title={title}
      ChildrenWrapper={ScrollingBlock}
      contentStyle={{ backgroundColor: 'white' }} // This is temporary until this screen gets redesigned
      addingProduct={true}>
      <SafeSecureCheckout
        paymentFrequency={paymentFrequency}
        price={price}
        selectedProductId={selectedProductId}
      />
      <Spacer y={6} />
      {error ? (
        <>
          <ErrorText style={{ height: 24 }}>{error}</ErrorText>
          <Spacer y={2.5} />
        </>
      ) : null}
      <CardSubmissionForm
        paymentMethod={paymentMethod}
        onSave={onCardSave}
        onNext={onNext}
        startEditing={() => {
          if (error) {
            setError(null);
          }
        }}
      />
      <Spacer y={3} />
      <PaymentGuarantee />
      <Spacer y={4.5} />
    </ContentUnified>
  );
};

PaymentInfoScreen.navigationOptions = {
  title,
  headerLeft: null,
  headerShown: Platform.OS !== 'web',
};

export default PaymentInfoScreen;
