import React from 'react';
import styled from 'styled-components/native';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const cardsSrc = require('./assets/accepted-cards.png');

const Cards = styled.Image`
  width: 120px;
  height: 32px;
`;

export default () => <Cards source={cardsSrc} />;
