import PetQuoteScreenView from './PetQuoteScreenView';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../AuthProvider';
import {
  GetDraftUserPolicies,
  LocalUser,
  RepriceDraftPolicies,
} from '../../../../operation-result-types';
import {
  DRAFT_POLICIES,
  GET_LOCAL_USER,
  REPRICE_DRAFT_POLICIES,
} from '../../GraphQL/Waffle/Queries';
import {
  findPetPolicy,
  getPetCoverages,
  mapDraftPolicyToPetScreenLocalState,
  petQuoteScreenInitialState,
  PetQuoteScreenLocalState,
} from './models/pet-quote-screen.model';
import {
  decrementValueAsync,
  incrementValuesAsync,
  setAppIsLoading,
  toggleValueAsync,
  updateChoiceValueAsync,
} from './models/value-updaters';
import {
  IllnessToggleValues,
  IllnessValues,
  WellnessChoiceValues,
  WellnessValues,
} from './utils/pet.constants';
import {
  fetchDraftPoliciesAsync,
  GetFullPolicy,
} from '../../Components/DesignPolicy/SuggestedCoverageShared';
import goToNextPolicyOrCheckoutAsync from '../QuoteScreen/utils/checkout-policy';
import { ProductPet } from '../../Constants/Products';
import { ShowWebLink } from '../../Components/WebViewPopupLink';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';

export default function PetQuoteScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Pet Quote'>): React.ReactElement {
  // Hooks
  const client = useApolloClient();
  const { isGuest } = useAuthContext();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);

  const { data, loading: draftPolicyIsLoading } =
    useQuery<GetDraftUserPolicies>(DRAFT_POLICIES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const [reprice] = useMutation<RepriceDraftPolicies>(REPRICE_DRAFT_POLICIES);
  const [localState, setLocalState] = useState<PetQuoteScreenLocalState>(
    petQuoteScreenInitialState
  );

  const draftPetPolicy = findPetPolicy(data?.draftUserPolicies);

  useEffect(() => {
    if (draftPolicyIsLoading) return;

    if (!draftPetPolicy || draftPetPolicy?.price === 0) {
      _rePriceAndFetchDraftPoliciesAsync().then();
    } else if (draftPetPolicy?.price !== localState.quotePrice) {
      setLocalState(
        setAppIsLoading<PetQuoteScreenLocalState>(
          draftPetPolicy,
          mapDraftPolicyToPetScreenLocalState,
          false
        )
      );
    }
  }, [draftPolicyIsLoading]);

  // Private functions
  const _rePriceAndFetchDraftPoliciesAsync = async () => {
    setLocalState(
      setAppIsLoading<PetQuoteScreenLocalState>(
        draftPetPolicy,
        mapDraftPolicyToPetScreenLocalState,
        true
      )
    );

    await reprice();
    const newDraftPolicies = await fetchDraftPoliciesAsync(client);
    const updatedPetPolicy = findPetPolicy(
      newDraftPolicies?.data?.draftUserPolicies
    );

    setLocalState(
      setAppIsLoading<PetQuoteScreenLocalState>(
        updatedPetPolicy,
        mapDraftPolicyToPetScreenLocalState,
        false
      )
    );
  };

  const {
    annualLimitCoverage,
    copayCoverage,
    deductibleCoverage,
    hasIllnessCoverage,
    wellnessCoverage,
  } = getPetCoverages(draftPetPolicy?.coverage);

  // Controls
  const annualLimitControl = {
    onIncreaseAsync: () =>
      incrementValuesAsync<PetQuoteScreenLocalState>({
        client,
        coverage: annualLimitCoverage,
        currentValue: localState.annualLimit,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
    onDecreaseAsync: () =>
      decrementValueAsync<PetQuoteScreenLocalState>({
        client,
        coverage: annualLimitCoverage,
        currentValue: localState.annualLimit,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
  };

  const copayControl = {
    onIncreaseAsync: () =>
      incrementValuesAsync<PetQuoteScreenLocalState>({
        client,
        coverage: copayCoverage,
        currentValue: localState.copay,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
    onDecreaseAsync: () =>
      decrementValueAsync<PetQuoteScreenLocalState>({
        client,
        coverage: copayCoverage,
        currentValue: localState.copay,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
  };

  const deductibleControl = {
    onIncreaseAsync: () =>
      incrementValuesAsync<PetQuoteScreenLocalState>({
        client,
        coverage: deductibleCoverage,
        currentValue: localState.deductible,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
    onDecreaseAsync: () =>
      decrementValueAsync<PetQuoteScreenLocalState>({
        client,
        coverage: deductibleCoverage,
        currentValue: localState.deductible,
        findPolicy: findPetPolicy,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        setState: setLocalState,
      }),
  };

  const hasIllNessControl = {
    onToggleAsync: () =>
      toggleValueAsync<PetQuoteScreenLocalState, IllnessToggleValues>({
        currentValue: localState.hasIllness
          ? IllnessValues.level2
          : IllnessValues.level1,
        client,
        coverage: hasIllnessCoverage,
        setState: setLocalState,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        findPolicy: findPetPolicy,
      }),
  };

  const hasWellnessBasicControl = {
    onToggleAsync: () => {
      if (localState.hasWellnessPrime) return;

      return updateChoiceValueAsync<
        PetQuoteScreenLocalState,
        WellnessChoiceValues
      >({
        newValue: localState.hasWellnessBasic
          ? WellnessValues.none
          : WellnessValues.basic,
        client,
        coverage: wellnessCoverage,
        setState: setLocalState,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        findPolicy: findPetPolicy,
      });
    },
  };

  const hasWellnessPrimeControl = {
    onToggleAsync: () => {
      if (localState.hasWellnessBasic) return;

      return updateChoiceValueAsync<
        PetQuoteScreenLocalState,
        WellnessChoiceValues
      >({
        newValue: localState.hasWellnessPrime
          ? WellnessValues.none
          : WellnessValues.prime,
        client,
        coverage: wellnessCoverage,
        setState: setLocalState,
        mapDraftPolicyToState: mapDraftPolicyToPetScreenLocalState,
        findPolicy: findPetPolicy,
      });
    },
  };

  return (
    <PetQuoteScreenView
      annualLimit={localState.annualLimit}
      annualLimitControl={annualLimitControl}
      copay={localState.copay}
      copayControl={copayControl}
      deductible={localState.deductible}
      deductibleControl={deductibleControl}
      hasIllness={localState.hasIllness}
      hasIllnessControl={hasIllNessControl}
      hasWellnessBasic={localState.hasWellnessBasic}
      hasWellnessBasicControl={hasWellnessBasicControl}
      hasWellnessPrime={localState.hasWellnessPrime}
      hasWellnessPrimeControl={hasWellnessPrimeControl}
      isLoading={localState.isLoading}
      navigation={navigation}
      onGoToNextPolicyOrCheckout={() =>
        goToNextPolicyOrCheckoutAsync({
          client,
          isGuest,
          user,
          navigation,
          policyProductIds: data?.draftUserPolicies.map((p) => p.productId),
        })
      }
      onOpenFullPolicy={() =>
        ShowWebLink(undefined, GetFullPolicy(user, ProductPet))
      }
      quotePrice={localState.quotePrice}
    />
  );
}
