import React from 'react';
import styled from 'styled-components/native';
import LockIcon from './LockIcon';
import PoweredByStripeIcon from './PoweredByStripeIcon';
import CardsIcons from './CardsIcons';
import { Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';

const Box = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WaffleBoldText = styled(WaffleText)`
  font-weight: bold;
`;

const PaymentContainers = styled.View`
  display: flex;
  flex-direction: row;
`;

export default function PaymentGuarantee(): React.ReactElement {
  return (
    <>
      <Box>
        <LockIcon />
        <Spacer x={0.5} />
        <WaffleText>
          Guaranteed <WaffleBoldText>Safe & Secure</WaffleBoldText> Checkout
        </WaffleText>
      </Box>
      <Spacer y={1} />
      <PaymentContainers>
        <PoweredByStripeIcon />
        <Spacer x={0.5} />
        <CardsIcons />
      </PaymentContainers>
    </>
  );
}
