import { IsLifePolicy, IsPetPolicy } from '../../Helper/PolicyHelper';
import { LinkToCFPet } from '../../Partners/LinkToCFPet';
import { LinkToLife } from './LinkToLife';
import React from 'react';
import { PolicyProps } from '../../../component-config';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';

const Text = styled(WaffleText)`
  font-size: 20px;
  line-height: 23px;
`;

export const EditOrLink = ({ policy }: PolicyProps) => {
  const isPet = IsPetPolicy(policy);

  if (isPet) {
    return (
      <LinkToCFPet>
        <Text style={{ marginVertical: 15 }}>
          You will now be taken to our partner, ASPCA® Pet Health Insurance, to
          view your policy. Please log into the site using your Waffle
          credentials.
        </Text>
        <Text style={{ marginBottom: 15 }}>
          If there are any issues, please use the Waffle chat and we will
          assist.
        </Text>
      </LinkToCFPet>
    );
  }

  if (IsLifePolicy(policy)) {
    return <LinkToLife userId={policy?.externalPolicy.ExternalUserId} />;
  }

  return <></>;
};
