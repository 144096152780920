import React from 'react';
import CoverageCard from '../../Components/CoverageCard';
import Toggle from '../../Components/Toggle';
import { ToggleControlValue } from './models/Controls';
import styled from 'styled-components/native';
import WaffleText from '../../Components/WaffleText';
import petImages from './utils/pet-images';
import { WaffleLightGrey } from '../../Constants/Style';
import LabeledIcon from './LabeldIcon';
import { Spacer } from '../../Components/SimpleComponents';
import CalloutText from './CalloutText';
import { Platform } from 'react-native';

type Props = {
  isDisabled: boolean;
  hasWellnessBasic: boolean;
  hasWellnessBasicControl: ToggleControlValue;
};

const Row = styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
`;

const TitleSection = styled.View`
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(WaffleText)`
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.5px;
  font-weight: 500;
`;

const HeaderRow = styled.View`
  flex-direction: row;
  width: 100%;
  max-height: 65px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-width: 3px;
  border-bottom-color: ${WaffleLightGrey};
  flex: 1;
`;

const wellnessPreventions = [
  {
    source: petImages.accidents,
    label: 'Annual Exam',
  },
  {
    source: petImages.deWorming,
    label: 'Deworming',
  },
  {
    source: petImages.poopTest,
    label: 'Fecal Test',
  },
  {
    source: petImages.vaccines,
    label: 'Vaccines',
  },
];

const PromotionBox = styled.View`
  background-color: rgba(250, 142, 70, 1);
  position: absolute;
  top: -18px;
  width: 202px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export default function WellnessBasic({
  isDisabled,
  hasWellnessBasic,
  hasWellnessBasicControl,
}: Props): React.ReactElement {
  return (
    <CoverageCard bgColor="white" isFocused={true}>
      <PromotionBox>
        <WaffleText style={{ color: 'rgba(255, 255, 255, 1)', fontSize: 16 }}>
          MOST POPULAR
        </WaffleText>
      </PromotionBox>

      {Platform.OS === 'ios' && <Spacer y={2} />}
      <HeaderRow>
        <TitleSection>
          <TitleText>Wellness Basic</TitleText>
          <CalloutText>(+$9.95/Mo)</CalloutText>
        </TitleSection>
        <Toggle
          isDisabled={isDisabled}
          isEnabled={hasWellnessBasic}
          onPress={hasWellnessBasicControl.onToggleAsync}
        />
      </HeaderRow>
      <Spacer x={2} y={2} />
      <Row>
        {wellnessPreventions.slice(0, 2).map((p) => (
          <LabeledIcon
            isActive={hasWellnessBasic}
            source={p.source}
            label={p.label}
            key={p.label}
          />
        ))}
      </Row>
      <Row>
        {wellnessPreventions.slice(2, 5).map((p) => (
          <LabeledIcon
            isActive={hasWellnessBasic}
            source={p.source}
            label={p.label}
            key={p.label}
          />
        ))}
      </Row>
    </CoverageCard>
  );
}
